import PageTitle from '../../components/page-title/PageTitle';
import Input, { InputType } from '../../components/input/Input';
import { SelectItem } from '../../components/select/Select';
import Button, { ButtonSize, ButtonType } from '../../components/button/Button';
import Tabs from '../../components/tabs/Tabs';
import TabItem from '../../components/tabs/TabItem';
import Container from '../../components/container/Container';
import { BrowserView, MobileView } from 'react-device-detect';
import BaseComponent, { IProfileState } from '../../components/BaseComponent';
import { ReferentialType } from '../../services/ReferentialsService';
import { AttachmentState } from '../../services/AttachmentService';
import ReferentialSelect from '../../components/referential-select/ReferentialSelect';
import Alert, { AlertSize, AlertType } from '../../components/alert/Alert';
import AttachmentUpload from '../../components/attachment-upload/AttachmentUpload';
import EnumGroupingType from '../../enums/EnumGroupingType';
import FieldCheckerService from '../../services/FieldCheckerService';
import EnumApplicantInputArmyType from '../../enums/EnumApplicantInputArmyType';
import FormatCheckService from '../../services/FormatCheckService';
import NationSelect from '../../components/referential-select/NationSelect';
import ReferentialSelectOptimise from '../../components/referential-select/ReferentialSelectOptimise';

interface IRepresentantLegalState extends IProfileState {
	zipCode?: string;
	emailError?: string;
	phoneError?: string;
	isUploadLegalRepresentative: boolean;
	isDeleteLegalRepresentative: boolean;
}

class RepresentantLegal extends BaseComponent<IRepresentantLegalState> {
	state: IRepresentantLegalState = {
		zipCode: '',
		isEdit: false,
		isDeleteLegalRepresentative: false,
		isUploadLegalRepresentative: false
	};
	legalQualityItems: SelectItem[] = [];
	nationItems: SelectItem[] = [];
	cityItems: SelectItem[] = [];

	groupingType: number = EnumGroupingType.INFORMATIONS_PERSONNELLES_REPRESENTANT_LEGAL;

	componentDidUpdate() {
		FieldCheckerService.check(
			EnumApplicantInputArmyType.REPRESENTANT_LEGAL_QUALITE_REPRESENTANT_LEGAL,
			this.state.profile?.personal?.legalRepresentative?.legalQualityId,
			this.requiredFields
		);
		FieldCheckerService.check(
			EnumApplicantInputArmyType.REPRESENTANT_LEGAL_NOM_DU_REPRESENTANT_LEGAL,
			this.state.profile?.personal?.legalRepresentative?.lastName,
			this.requiredFields
		);
		FieldCheckerService.check(
			EnumApplicantInputArmyType.REPRESENTANT_LEGAL_PRENOM_DU_REPRESENTANT_LEGAL,
			this.state.profile?.personal?.legalRepresentative?.firstName,
			this.requiredFields
		);
		FieldCheckerService.check(
			EnumApplicantInputArmyType.REPRESENTANT_LEGAL_ADRESSE_DU_REPRESENTANT_LEGAL,
			this.state.profile?.personal?.legalRepresentative?.address?.street,
			this.requiredFields
		);
		FieldCheckerService.check(
			EnumApplicantInputArmyType.REPRESENTANT_LEGAL_COMPLEMENT_D_ADRESSE_DU_REPRESENTANT_LEGAL,
			this.state.profile?.personal?.legalRepresentative?.address?.additionalInformation,
			this.requiredFields
		);
		FieldCheckerService.check(
			EnumApplicantInputArmyType.REPRESENTANT_LEGAL_CODE_POSTAL_DU_REPRESENTANT_LEGAL,
			this.state.profile?.personal?.legalRepresentative?.address?.cityId,
			this.requiredFields
		);
		FieldCheckerService.check(
			EnumApplicantInputArmyType.REPRESENTANT_LEGAL_CODE_POSTAL_DU_REPRESENTANT_LEGAL,
			this.state.profile?.personal?.legalRepresentative?.address?.city?.zipCode,
			this.requiredFields
		);
		FieldCheckerService.check(
			EnumApplicantInputArmyType.REPRESENTANT_LEGAL_VILLE_OU_COMMUNE_DU_REPRESENTANT_LEGAL,
			this.state.profile?.personal?.legalRepresentative?.address?.city,
			this.requiredFields
		);
		FieldCheckerService.check(
			EnumApplicantInputArmyType.REPRESENTANT_LEGAL_VILLE_OU_COMMUNE_DU_REPRESENTANT_LEGAL,
			this.state.profile?.personal?.legalRepresentative?.address?.city,
			this.requiredFields
		);
		FieldCheckerService.check(
			EnumApplicantInputArmyType.REPRESENTANT_LEGAL_PAYS_DE_RESIDENCE_DU_REPRESENTANT_LEGAL,
			this.state.profile?.personal?.legalRepresentative?.address?.countryId,
			this.requiredFields
		);
		FieldCheckerService.check(
			EnumApplicantInputArmyType.REPRESENTANT_LEGAL_NUMERO_DE_TELEPHONE_DU_REPRESENTANT_LEGAL,
			this.state.profile?.personal?.legalRepresentative?.phoneNumber?.number,
			this.requiredFields
		);
		FieldCheckerService.check(
			EnumApplicantInputArmyType.REPRESENTANT_LEGAL_ADRESSE_ELECTRONIQUE_DU_REPRESENTANT_LEGAL,
			this.state.profile?.personal?.legalRepresentative?.email,
			this.requiredFields
		);
		FieldCheckerService.check(
			EnumApplicantInputArmyType.REPRESENTANT_LEGAL_CONSENTEMENT_DU_REPRESENTANT_LEGAL,
			this.state.profile?.personal?.legalRepresentative?.representativeConsentSignedAndUploaded?.id,
			this.requiredFields
		);
	}

	deleteLegalRepresentativeDocument() {
		this.setState({
			profile: {
				...this.state.profile,
				personal: {
					...this.state.profile?.personal,
					legalRepresentative: {
						...this.state.profile?.personal?.legalRepresentative,
						representativeConsentSignedAndUploaded: undefined
					}
				}
			},
			isDeleteLegalRepresentative: true
		});
	}

	setLegalRepresentativeDocument(guid?: string) {
		this.setState({
			profile: {
				...this.state.profile,
				personal: {
					...this.state.profile?.personal,
					legalRepresentative: {
						...this.state.profile?.personal?.legalRepresentative,
						representativeConsentSignedAndUploaded: {
							id: guid,
							validationState: AttachmentState.WAITING_FOR_VALIDATION
						}
					}
				}
			},
			isUploadLegalRepresentative: true
		});
	}

	initUpload() {
		this.setState({
			isUploadLegalRepresentative: false,
			isDeleteLegalRepresentative: false
		});
	}

	render() {
		return (
			<Container isLoading={this.state.isLoading}>
				<div className='fr-grid-row fr-grid-row--center fr-grid-row--gutters'>
					<div className='fr-col-11 fr-col-lg-8'>
						<PageTitle
							title='Représentant légal'
							description='Veuillez remplir les champs d’informations ci-dessous, mais également importer les différentes pièces jointes demandées'
						/>
					</div>
				</div>
				<div className='fr-grid-row fr-grid-row--center fr-grid-row--gutters'>
					<div className='fr-col-12 fr-col-lg-8'>
						{this.state.isSuccess && this.state.groupingIsValidate == false && (
							<Alert
								description='Les informations renseignées ont été enregistrées avec succès'
								hasCloseButton={true}
								size={AlertSize.Small}
								type={AlertType.Success}
								isShown={this.state.isSuccess}
								onHide={() => this.setState({ isSuccess: false })}
							/>
						)}
					</div>
				</div>
				<div className='fr-grid-row fr-grid-row--center fr-grid-row--gutters'>
					<div className='fr-col-12 fr-col-lg-8'>
						<Tabs
							className='tabs-with-overflow'
							mobileOnly={true}
							isFormContainer={true}
							items={[
								{
									displayName: 'Formulaire',
									id: 'form',
									tabIndex: 0,
									selected: true
								},
								{
									displayName: 'Pièces jointes',
									id: 'attachments',
									tabIndex: 1,
									selected: false
								}
							]}>
							<TabItem
								id='form'
								tabIndex={0}
								selected={true}
								mobileOnly={true}>
								<div className='fr-grid-row'>
									<div className='fr-col-12'>
										<ReferentialSelect
											label='Qualité du représentant légal'
											onChange={(e) =>
												this.setState({
													profile: {
														...this.state.profile,
														personal: {
															...this.state.profile?.personal,
															legalRepresentative: {
																...this.state.profile?.personal?.legalRepresentative,
																legalQualityId: e?.id
															}
														}
													}
												})
											}
											value={this.state.profile?.personal?.legalRepresentative?.legalQualityId}
											disabled={this.state.groupingIsValidate ?? false}
											type={ReferentialType.LEGAL_QUALITY}
										/>
									</div>
								</div>
								<div className='fr-grid-row'>
									<div className='fr-col-12'>
										<Input
											title='Nom du représentant légal'
											description=''
											value={this.state.profile?.personal?.legalRepresentative?.lastName || ''}
											disabled={this.state.groupingIsValidate ?? false}
											type={InputType.Text}
											iconClass=''
											onChange={(e) =>
												this.setState({
													profile: {
														...this.state.profile,
														personal: {
															...this.state.profile?.personal,
															legalRepresentative: {
																...this.state.profile?.personal?.legalRepresentative,
																lastName: e
															}
														}
													}
												})
											}></Input>
									</div>
								</div>
								<div className='fr-grid-row'>
									<div className='fr-col-12'>
										<Input
											title='Prénom du représentant légal'
											description=''
											value={this.state.profile?.personal?.legalRepresentative?.firstName || ''}
											disabled={this.state.groupingIsValidate ?? false}
											type={InputType.Text}
											iconClass=''
											onChange={(e) =>
												this.setState({
													profile: {
														...this.state.profile,
														personal: {
															...this.state.profile?.personal,
															legalRepresentative: {
																...this.state.profile?.personal?.legalRepresentative,
																firstName: e
															}
														}
													}
												})
											}></Input>
									</div>
								</div>
								<div className='fr-grid-row'>
									<div className='fr-col-12'>
										<Input
											title='Adresse du représentant légal'
											description='Numéro et voie'
											value={this.state.profile?.personal?.legalRepresentative?.address?.street || ''}
											disabled={this.state.groupingIsValidate ?? false}
											type={InputType.Text}
											iconClass=''
											onChange={(e) =>
												this.setState({
													profile: {
														...this.state.profile,
														personal: {
															...this.state.profile?.personal,
															legalRepresentative: {
																...this.state.profile?.personal?.legalRepresentative,
																address: {
																	...this.state.profile?.personal?.legalRepresentative?.address,
																	street: e
																}
															}
														}
													}
												})
											}></Input>
									</div>
								</div>
								<div className='fr-grid-row'>
									<div className='fr-col-12'>
										<Input
											title='Complément d’adresse du représentant légal'
											description='Bâtiment, immeuble, escalier et numéro d’appartement'
											value={
												this.state.profile?.personal?.legalRepresentative?.address
													?.additionalInformation || ''
											}
											disabled={this.state.groupingIsValidate ?? false}
											type={InputType.Text}
											iconClass=''
											onChange={(e) =>
												this.setState({
													profile: {
														...this.state.profile,
														personal: {
															...this.state.profile?.personal,
															legalRepresentative: {
																...this.state.profile?.personal?.legalRepresentative,
																address: {
																	...this.state.profile?.personal?.legalRepresentative?.address,
																	additionalInformation: e
																}
															}
														}
													}
												})
											}></Input>
									</div>
								</div>
								<div className='fr-grid-row'>
									<div className='fr-col-12'>
										<NationSelect
											label='Pays de résidence du représentant légal'
											onChange={(e) =>
												this.setState({
													showFreeCityFieldsForLegalRepresentativeAddressCountryId:
														e?.name !== 'FRANCE',
													profile: {
														...this.state.profile,
														personal: {
															...this.state.profile?.personal,
															legalRepresentative: {
																...this.state.profile?.personal?.legalRepresentative,
																address: {
																	...this.state.profile?.personal?.legalRepresentative?.address,
																	countryId: e?.id,
																	country: {
																		...this.state.profile?.personal?.legalRepresentative
																			?.address?.country,
																		name: e?.name,
																		shortName: e?.shortname,
																		nationality: e?.nationality
																	},
																	cityId: undefined,
																	city: {
																		...this.state.profile?.personal?.legalRepresentative
																			?.address?.city,
																		id: undefined,
																		zipCode: e?.name === 'MONACO' ? '98000' : ''
																	}
																}
															}
														}
													}
												})
											}
											value={this.state.profile?.personal?.legalRepresentative?.address?.countryId}
											disabled={this.state.groupingIsValidate ?? false}
											type={ReferentialType.NATION}
											customOptionLabel={(o) => o.shortName}
										/>
									</div>
								</div>
								{this.state.showFreeCityFieldsForLegalRepresentativeAddressCountryId && (
									<>
										<div className='fr-grid-row'>
											<div className='fr-col-12'>
												<Input
													title='Code postal du représentant légal'
													type={InputType.Text}
													onChange={(e) =>
														this.setState({
															profile: {
																...this.state.profile,
																personal: {
																	...this.state.profile?.personal,
																	legalRepresentative: {
																		...this.state.profile?.personal?.legalRepresentative,
																		address: {
																			...this.state.profile?.personal?.legalRepresentative
																				?.address,
																			city: {
																				...this.state.profile?.personal
																					?.legalRepresentative?.address?.city,
																				zipCode: e
																			}
																		}
																	}
																}
															}
														})
													}
													value={
														this.state.profile?.personal?.legalRepresentative?.address?.city?.zipCode
													}
													disabled={this.state.groupingIsValidate ?? false}></Input>
											</div>
										</div>
										<div className='fr-grid-row'>
											<div className='fr-col-12'>
												<Input
													title='Ville ou commune du représentant légal'
													type={InputType.Text}
													onChange={(e) =>
														this.setState({
															profile: {
																...this.state.profile,
																personal: {
																	...this.state.profile?.personal,
																	legalRepresentative: {
																		...this.state.profile?.personal?.legalRepresentative,
																		address: {
																			...this.state.profile?.personal?.legalRepresentative
																				?.address,
																			city: {
																				...this.state.profile?.personal
																					?.legalRepresentative?.address?.city,
																				name: e
																			}
																		}
																	}
																}
															}
														})
													}
													value={this.state.profile?.personal?.legalRepresentative?.address?.city?.name}
													disabled={this.state.groupingIsValidate ?? false}></Input>
											</div>
										</div>
									</>
								)}
								{!this.state.showFreeCityFieldsForLegalRepresentativeAddressCountryId && (
									<>
										<div className='fr-grid-row'>
											<div className='fr-col-12'>
												<ReferentialSelectOptimise
													label='Code postal du représentant légal'
													onChange={(e) =>
														this.setState({
															zipCode: e?.zipCode,
															profile: {
																...this.state.profile,
																personal: {
																	...this.state.profile?.personal,
																	legalRepresentative: {
																		...this.state.profile?.personal?.legalRepresentative,
																		address: {
																			...this.state.profile?.personal?.legalRepresentative
																				?.address,
																			cityId: undefined,
																			city: {
																				...this.state.profile?.personal
																					?.legalRepresentative?.address?.city,
																				id: e?.id,
																				zipCode: e?.zipCode
																			}
																		}
																	}
																}
															}
														})
													}
													zipCode={
														this.state.profile?.personal?.legalRepresentative?.address?.city?.zipCode
													}
													value={this.state.profile?.personal?.legalRepresentative?.address?.city?.id}
													disabled={this.state.groupingIsValidate ?? false}
													type={ReferentialType.ZIPCODE}
													customOptionLabel={(o) => o.zipCode}
												/>
											</div>
										</div>
										<div className='fr-col-12'>
											<ReferentialSelectOptimise
												label='Ville ou commune du représentant légal'
												onChange={(e) =>
													this.setState({
														profile: {
															...this.state.profile,
															personal: {
																...this.state.profile?.personal,
																legalRepresentative: {
																	...this.state.profile?.personal?.legalRepresentative,
																	address: {
																		...this.state.profile?.personal?.legalRepresentative
																			?.address,
																		cityId: e?.id,
																		city: {
																			...this.state.profile?.personal?.legalRepresentative
																				?.address?.city,
																			id: e?.id,
																			zipCode: e?.zipCode,
																			name: e?.name != 'Sélectionnez' ? e?.name : undefined
																		}
																	}
																}
															}
														}
													})
												}
												value={this.state.profile?.personal?.legalRepresentative?.address?.cityId}
												disabled={this.state.groupingIsValidate ?? false}
												type={ReferentialType.CITY}
												zipCode={this.state.zipCode}
												name={this.state.profile?.personal?.legalRepresentative?.address?.city?.name}
											/>
										</div>
									</>
								)}
								<div className='fr-grid-row'>
									<div className='fr-col-12'>
										<Input
											title='Numéro de téléphone du représentant légal'
											description='Format attendu : (+33)xxxxxxxxx'
											value={this.state.profile?.personal?.legalRepresentative?.phoneNumber?.number || ''}
											disabled={this.state.groupingIsValidate ?? false}
											type={InputType.Tel}
											errorMessage={this.state.phoneError}
											iconClass=''
											onChange={(e) => {
												if (e.startsWith('(+33)')) {
													if (e.length > 14) {
														return;
													}
												} else {
													if (e.length > 30) {
														return;
													}
												}
												var isValid = FormatCheckService.checkPhoneNumber(e);

												this.setState({
													profile: {
														...this.state.profile,
														personal: {
															...this.state.profile?.personal,
															legalRepresentative: {
																...this.state.profile?.personal?.legalRepresentative,
																phoneNumber: {
																	...this.state.profile?.personal?.legalRepresentative
																		?.phoneNumber,
																	number: e
																}
															}
														}
													},
													phoneError: isValid ? '' : 'Le format du numéro de téléphone est erroné.'
												});
											}}></Input>
									</div>
								</div>
								<div className='fr-grid-row'>
									<div className='fr-col-12'>
										<Input
											title='Adresse électronique du représentant légal'
											description='Format attendu : nom@domaine.fr'
											value={this.state.profile?.personal?.legalRepresentative?.email || ''}
											disabled={this.state.groupingIsValidate ?? false}
											type={InputType.Email}
											iconClass=''
											onChange={(e) =>
												this.setState({
													profile: {
														...this.state.profile,
														personal: {
															...this.state.profile?.personal,
															legalRepresentative: {
																...this.state.profile?.personal?.legalRepresentative,
																email: e
															}
														}
													}
												})
											}
											errorMessage={this.state.emailError}
											onBlur={(e) => {
												const regex = RegExp(
													"^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$"
												);
												this.setState({
													emailError: !regex.test(e) ? "Format d'adresse électronique incorrect" : ''
												});
											}}></Input>
									</div>
								</div>
							</TabItem>
							<BrowserView>
								<hr className='fr-hr desktop-only' />
								<h5 className='desktop-only'>Pièces jointes</h5>
							</BrowserView>
							<TabItem
								id='attachments'
								tabIndex={1}
								selected={false}
								mobileOnly={true}>
								<div className='fr-grid-row'>
									<div className='fr-col-12'>
										<AttachmentUpload
											hintText='Consentement du représentant légal'
											guid={
												this.state.profile?.personal?.legalRepresentative
													?.representativeConsentSignedAndUploaded?.id
											}
											validationState={
												this.state.profile?.personal?.legalRepresentative
													?.representativeConsentSignedAndUploaded?.validationState
											}
											isDisabled={this.state.groupingIsValidate ?? false}
											onDelete={this.deleteLegalRepresentativeDocument.bind(this)}
											onUpload={this.setLegalRepresentativeDocument.bind(this)}
											isUploadInProgress={this.state.isUploadLegalRepresentative}
											isDeleteInProgress={this.state.isDeleteLegalRepresentative}
										/>
									</div>
								</div>
							</TabItem>
							<BrowserView>
								{!this.state.groupingIsValidate && (
									<div className='fr-grid-row fr-grid-row--right'>
										<div className='fr-col-12 fr-col-md-6 fr-align-right'>
											<Button
												disabled={false}
												onClick={() => {
													this.updateProfile(() => {
														this.displaySuccess();
														this.initUpload();
													});
												}}
												text='Enregistrer mes informations'
												type={ButtonType.Primary}
												iconClassName=''
												size={ButtonSize.Medium}
												modalId=''></Button>
										</div>
									</div>
								)}
							</BrowserView>
						</Tabs>
						<MobileView>
							{!this.state.groupingIsValidate && (
								<div className='fr-grid-row fr-grid-row--center'>
									<div className='fr-col-8'>
										<Button
											className='mobile-button'
											disabled={false}
											onClick={() => {
												this.updateProfile(() => {
													this.displaySuccess();
													this.initUpload();
												});
											}}
											text='Enregistrer mes informations'
											type={ButtonType.Primary}
											iconClassName=''
											size={ButtonSize.Medium}
											modalId=''></Button>
									</div>
								</div>
							)}
						</MobileView>
					</div>
				</div>
			</Container>
		);
	}
}

export default RepresentantLegal;
