/* Composant pour l'upload des pièces jointes */

import React from 'react';

import './0-Upload-mobile.scss';
import Alert, { AlertSize, AlertType } from '../alert/Alert';

interface IUploadState {
	isDisabled: boolean;
	extensionError: boolean;
}

const validExtensions: string[] = [
	'jpg',
	'JPG',
	'jpeg',
	'JPEG',
	'pdf',
	'PDF',
	'doc',
	'DOC',
	'docx',
	'DOCX',
	'png',
	'PNG',
	'odt',
	'ODT',
	'rtf',
	'RTF'
];

class Upload extends React.Component<
	{
		title?: string;
		hintText?: string;
		errorMessage?: string;
		isDisabled?: boolean;
		isUploadInProgress?: boolean;
		isDeleteInProgress?: boolean;
		guid?: string;
		noExplanation?: boolean;
		onChange?: (e: File | null) => void;
		assignMe?: (e: Upload) => void;
	},
	{}
> {
	state: IUploadState = {
		isDisabled: false,
		extensionError: false
	};

	inputFile?: HTMLInputElement | null;

	constructor(props: any) {
		super(props);
		this.state.isDisabled = this.props.isDisabled ?? false;
		if (this.props.assignMe) this.props.assignMe(this);
	}

	componentDidUpdate() {
		if (
			!this.props.isUploadInProgress &&
			!this.props.isDeleteInProgress &&
			(!this.props.guid || this.props.guid === '00000000-0000-0000-0000-000000000000')
		) {
			if (this.state.isDisabled) this.setState({ isDisabled: false });
		}
	}

	click() {
		this.setIsDisabled(false, () => this.inputFile?.click());
	}

	handleChange(e: React.FormEvent<HTMLInputElement>) {
		if (!this.props.onChange) return;
		if (!e.currentTarget.files || e.currentTarget.files.length === 0) {
			this.props.onChange(null);
			return;
		}

		var regResult = /^.+\.([^.]+)$/.exec(e.currentTarget.files[0].name);
		var extension = regResult == null ? '' : regResult[1];

		if (validExtensions.indexOf(extension) > -1) {
			this.props.onChange(e.currentTarget.files[0]);
			this.setState({ extensionError: false });
		} else {
			this.setState({ extensionError: true });
		}
	}

	setIsDisabled(isDisabled: boolean, callback?: () => void) {
		this.setState({ isDisabled: isDisabled }, callback);
	}

	render() {
		var errorClass = this.props.errorMessage ? 'fr-input-group--error' : '';

		return (
			<div>
				<Alert
					description='Les formats supportés sont: .odt, .rtf, .pdf, .png, .jpg, .jpeg, .doc, .docx'
					size={AlertSize.Small}
					type={AlertType.Error}
					isShown={this.state.extensionError}></Alert>
				<div
					className={'fr-upload-group ' + errorClass}
					style={{ marginTop: '25px' }}>
					<label
						className='fr-label'
						htmlFor={'upload-' + this.props.hintText}>
						{this.props.title && <h5 className='fr-h5'>{this.props.title}</h5>}
						{this.props.hintText && <p>{this.props.hintText}</p>}
						{!this.props.noExplanation && (
							<>
								<span className='fr-hint-text'>Taille maximale : 10 Mo.</span>
								<span className='fr-hint-text'>Formats supportés : jpg, png, pdf...</span>
							</>
						)}
					</label>
					{!this.state.isDisabled && (
						<input
							id={'upload-' + this.props.hintText}
							accept='.jpg,.jpeg,.odt,.rtf,.pdf,.png,.doc,.docx'
							ref={(input) => {
								this.inputFile = input;
							}}
							className='fr-upload'
							type='file'
							disabled={this.props.isDisabled}
							style={{
								display:
									!this.props.isUploadInProgress &&
									!this.props.isDeleteInProgress &&
									!this.state.isDisabled &&
									(!this.props.guid || this.props.guid === '00000000-0000-0000-0000-000000000000')
										? 'block'
										: 'none'
							}}
							onChange={this.handleChange.bind(this)}></input>
					)}
					{this.props.isUploadInProgress && (
						<span role='status'>
							Pièce jointe chargée avec succès, n'oubliez pas de cliquer sur 'Enregistrer mes informations' pour que
							la pièce jointe soit bien enregistrée.
						</span>
					)}
					{this.props.isDeleteInProgress && (
						<span role='status'>
							Pièce jointe marquée pour être supprimé. N'oubliez pas de cliquer sur 'Enregistrer mes informations'
							pour valider sa suppression.
						</span>
					)}
				</div>
				{this.props.errorMessage && (
					<p
						role='alert'
						id='file-upload-with-error-desc-error'
						className='fr-error-text'>
						{this.props.errorMessage}
					</p>
				)}
			</div>
		);
	}
}

export default Upload;
