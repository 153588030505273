import React from 'react';
import QueryString, { ParsedQs } from 'qs';
import CardContainer from '../components/card-container/CardContainer';
import Card from '../components/card/Card';
import PageTitle from '../components/page-title/PageTitle';
import Tabs from '../components/tabs/Tabs';
import TabItem from '../components/tabs/TabItem';
import Button, { ButtonType, ButtonSize } from '../components/button/Button';
import EnumArmy from '../enums/EnumArmy';
import { v4 as uuid } from 'uuid';

interface IVitinesState {
	rdvId: string;
	armyId: string;
	categoryId: string;
	countArmy1: string;
	countArmy2: string;
	countArmy3: string;
	offres1: any;
	offres2: any;
	offres3: any;
}

class Vitrines extends React.Component<{ location: Location }, IVitinesState> {
	constructor(props: any) {
		super(props);

		const parsedQueryString = QueryString.parse(this.props.location.search, {
			ignoreQueryPrefix: true
		});

		const offres1 = [
			{
				jobTitle: 'BRANCARDIER SECOURISTE',
				jobCategoryId: '1',
				description: 'Militaire du rang'
			},
			{
				jobTitle: 'AIDE CAVALIER SOIGNEUR',
				jobCategoryId: '1',
				description: 'Militaire du rang'
			},
			{
				jobTitle: "COMBATTANT SPÉCIALISTE DE L'ESCORTE",
				jobCategoryId: '1',
				description: 'Militaire du rang'
			},
			{
				jobTitle: 'COMBATTANT ENGINS BLINDÉS',
				jobCategoryId: '1',
				description: 'Militaire du rang'
			},
			{
				jobTitle: 'MECANICIEN DETECTION ELECTROMAGNETIQUE MOYENS SOL AERONAUTIQUES',
				jobCategoryId: '2',
				description: 'Sous-officier'
			},
			{
				jobTitle: 'PRÉPARATEUR DE MISSION',
				jobCategoryId: '2',
				description: 'Sous-officier'
			},
			{
				jobTitle: 'CONTRÔLEUR AÉRIEN',
				jobCategoryId: '2',
				description: 'Sous-officier'
			},
			{
				jobTitle: 'MÉTÉOROLOGUE',
				jobCategoryId: '2',
				description: 'Sous-officier'
			},
			{
				jobTitle: 'OFFICIER EXPERT CYBERSÉCURITÉ',
				jobCategoryId: '3',
				description: 'Officier Spécialiste'
			},
			{
				jobTitle: 'OFFICIER HISTORIEN',
				jobCategoryId: '3',
				description: 'Officier Spécialiste'
			},
			{
				jobTitle: 'CHEF DE MUSIQUE',
				jobCategoryId: '3',
				description: 'Officier Spécialiste'
			},
			{
				jobTitle: 'OFFICIER MÉCANICIEN DES MATÉRIELS AÉRIENS',
				jobCategoryId: '3',
				description: 'Officier Spécialiste'
			},
			{
				jobTitle: "OFFICIER PILOTE D’HÉLICOPTÈRE / D'AVION",
				jobCategoryId: '3',
				description: 'Officier'
			},
			{
				jobTitle: "OFFICIER SOUS CONTRAT D'ENCADREMENT INFORMATIQUE & TÉLÉCOMS",
				jobCategoryId: '3',
				description: 'Officier'
			}
		];

		const offres2 = [
			{
				jobTitle: 'TECHNICIEN AÉRONAUTIQUE - AVIONIQUE',
				jobCategoryId: '4',
				description: 'Non-officier'
			},
			{
				jobTitle: 'MATELOT RESTAURATEUR',
				jobCategoryId: '4',
				description: 'Non-officier'
			},
			{
				jobTitle: 'MÉCANICIEN ET ÉLECTROTECHNICIEN NAVAL',
				jobCategoryId: '4',
				description: 'Non-officier'
			},
			{
				jobTitle: "RESPONSABLE TACTIQUE DE L'AÉRONAUTIQUE (TACAE)",
				jobCategoryId: '4',
				description: 'Non-officier'
			},
			{
				jobTitle: 'OPÉRATEUR INDUSTRIEL EN ATELIER NAVAL',
				jobCategoryId: '4',
				description: 'Non-officier'
			},
			{
				jobTitle: 'MUSICIEN DE LA FLOTTE',
				jobCategoryId: '4',
				description: 'Non-officier'
			},
			{
				jobTitle: 'CHEF DE PROJETS SIRH',
				jobCategoryId: '5',
				description: 'Officier'
			},
			{
				jobTitle: 'CHEF DE SECTEUR RADIOPROTECTION',
				jobCategoryId: '5',
				description: 'Officier'
			},
			{
				jobTitle: 'INGÉNIEUR CYBERDÉFENSE',
				jobCategoryId: '5',
				description: 'Officier'
			},
			{
				jobTitle: 'COORDONNATEUR NAVIRES SCIENTIFIQUES',
				jobCategoryId: '5',
				description: 'Officier'
			},
			{
				jobTitle: 'INGÉNIEUR MAINTENANCE NAVALE',
				jobCategoryId: '5',
				description: 'Officier'
			},
			{
				jobTitle: 'EVALUATEUR DES JOURNÉES DÉFENSE ET CITOYENNETÉ',
				jobCategoryId: '5',
				description: 'Officier'
			},
			{
				jobTitle: 'CHARGÉ DE RECRUTEMENT ET RELATIONS ÉCOLES',
				jobCategoryId: '5',
				description: 'Officier'
			},
			{
				jobTitle: "OFFICIER DE SÉCURITÉ DES SYSTÈMES D'INFORMATION",
				jobCategoryId: '5',
				description: 'Officier'
			}
		];

		const offres3 = [
			{
				jobTitle: 'ÉQUIPIER FUSILIER DE L’AIR',
				jobCategoryId: '6',
				description: 'Militaires du Rang'
			},
			{
				jobTitle: 'EQUIPIER MAITRE CHIEN PARACHUTISTE',
				jobCategoryId: '6',
				description: 'Militaires du Rang'
			},
			{
				jobTitle: 'ÉQUIPIER MAÎTRE-CHIEN',
				jobCategoryId: '6',
				description: 'Militaires du Rang'
			},
			{
				jobTitle: 'EQUIPIER POMPIER',
				jobCategoryId: '6',
				description: 'Militaires du Rang'
			},
			{
				jobTitle: 'TECHNICIEN RENSEIGNEMENT IMAGES',
				jobCategoryId: '7',
				description: 'Sous-officier'
			},
			{
				jobTitle: 'TECHNICIEN TÉLÉCOMMUNICATION ET RÉSEAUX',
				jobCategoryId: '7',
				description: 'Sous-officier'
			},
			{
				jobTitle: 'TECHNICIEN INTERCEPTION DES SIGNAUX',
				jobCategoryId: '7',
				description: 'Sous-officier'
			},
			{
				jobTitle: 'MAÎTRE-CHIEN PARACHUTISTE',
				jobCategoryId: '7',
				description: 'Sous-officier'
			},
			{
				jobTitle: 'RESPONSABLE DÉFENSE SOL-AIR',
				jobCategoryId: '8',
				description: 'Officiers'
			},
			{
				jobTitle: 'RESPONSABLE OPÉRATIONS AÉRIENNES',
				jobCategoryId: '8',
				description: 'Officiers'
			},
			{
				jobTitle: 'PILOTE DE CHASSE',
				jobCategoryId: '8',
				description: 'Officiers'
			},
			{
				jobTitle: 'PILOTE DE DRONE',
				jobCategoryId: '8',
				description: 'Officiers'
			},
			{
				jobTitle: 'INGÉNIEUR AÉRONAUTIQUE',
				jobCategoryId: '8',
				description: 'Officiers'
			},
			{
				jobTitle: 'INGÉNIEUR GÉNIE CIVIL',
				jobCategoryId: '8',
				description: 'Officiers'
			}
		];

		this.state = {
			rdvId: '',
			armyId: parsedQueryString.armyId ? parsedQueryString.armyId.toString() : '',
			categoryId: parsedQueryString.categoryId ? parsedQueryString.categoryId.toString() : '',
			countArmy1: offres1.length.toString(),
			countArmy2: offres2.length.toString(),
			countArmy3: offres3.length.toString(),
			offres1: offres1,
			offres2: offres2,
			offres3: offres3
		};
	}

	componentDidMount() {
		const newRdvId = uuid();
		localStorage.setItem('rdvId', newRdvId);
		this.setState({ rdvId: newRdvId });
		localStorage.removeItem('armyId');
	}

	buildCards(offres: any, armyName: string, armyId: string, originURL: string, originId: string, categoryId: string) {
		return offres.map((offre: any) => {
			if (categoryId == '' || offre.jobCategoryId == categoryId) {
				return (
					<Card
						key={offre.jobTitle}
						topDetail={{ IconClass: 'fr-icon-arrow-right-line', DisplayName: armyName }}
						title={offre.jobTitle}
						hasBottomArrow={false}
						isHorizontal={true}
						linkUrl='#'
						links={[
							{
								DisplayName: 'Postuler',
								Link:
									'/postuler?army_id=' +
									armyId +
									'&origin_url=' +
									originURL +
									'&origin_id=' +
									originId +
									'&job_category_id=' +
									offre.jobCategoryId +
									'&job_title=' +
									offre.jobTitle,
								IconClass: 'fr-icon-arrow-right-line'
							},
							{
								DisplayName: 'Votre Compte',
								Link:
									'/login?army_id=' +
									armyId +
									'&origin_url=' +
									originURL +
									'&origin_id=' +
									originId +
									'&job_category_id=' +
									offre.jobCategoryId +
									'&job_title=' +
									offre.jobTitle,
								IconClass: 'fr-icon-arrow-right-line'
							},
							{
								DisplayName: 'Prendre RDV',
								Link:
									'/prise-de-rdv?army_id=' +
									armyId +
									'&origin_url=' +
									originURL +
									'&origin_id=' +
									originId +
									'&job_category_id=' +
									offre.jobCategoryId +
									'&job_title=' +
									offre.jobTitle +
									'&rdv_id=' +
									this.state.rdvId,
								IconClass: 'fr-icon-arrow-right-line'
							}
						]}
						description={offre.description}></Card>
				);
			}
		});
	}

	buildArmy(army: EnumArmy) {
		var cat = this.state.categoryId;

		if (army === EnumArmy.ADT) {
			if (cat !== '1' && cat !== '2' && cat !== '3') {
				cat = '';
			}

			return this.buildCards(this.state.offres1, 'Armée de Terre', '1', 'sengager.fr', '1', cat);
		} else if (army === EnumArmy.MN) {
			if (cat !== '4' && cat !== '5') {
				cat = '';
			}

			return this.buildCards(this.state.offres2, 'Marine nationale', '2', 'lamarinerecrute.fr', '2', cat);
		} else {
			if (cat !== '6' && cat !== '7' && cat !== '8') {
				cat = '';
			}

			return this.buildCards(this.state.offres3, "Armée de l'Air et de l'Espace", '3', 'devenir-aviateur.fr', '3', cat);
		}
	}

	setfilter(armyId: string, categoryId: string) {
		this.setState({ armyId: armyId, categoryId: categoryId });
		this.render();
	}

	render() {
		return (
			<>
				<div className='fr-container'>
					<div className='fr-grid-row'>
						<div
							className='fr-col-12'
							style={{ marginTop: '2rem' }}>
							<PageTitle
								imgLink=''
								title='Simulateur des sites vitrines'
								description='Choisir une armée et une offre'
							/>
						</div>
						<div className='fr-col-12'>
							<Tabs
								mobileOnly={false}
								isFormContainer={true}
								items={[
									{
										displayName: 'Armée de Terre (' + this.state.countArmy1 + ')',
										id: 'army1',
										tabIndex: 0,
										selected: this.state.armyId == '' || this.state.armyId == '1' ? true : false
									},
									{
										displayName: 'Marine nationale (' + this.state.countArmy2 + ')',
										id: 'army2',
										tabIndex: 1,
										selected: this.state.armyId == '2' ? true : false
									},
									{
										displayName: "Armée de l'Air et de l'Espace (" + this.state.countArmy3 + ')',
										id: 'army3',
										tabIndex: 2,
										selected: this.state.armyId == '3' ? true : false
									}
								]}>
								<TabItem
									id='army1'
									tabIndex={0}
									selected={true}
									mobileOnly={false}
									noBackroundGray={true}>
									<Button
										text='Tous'
										type={
											this.state.categoryId != '1' &&
											this.state.categoryId != '2' &&
											this.state.categoryId != '3'
												? ButtonType.Primary
												: ButtonType.Secondary
										}
										size={ButtonSize.Medium}
										onClick={() => this.setfilter('1', '')}></Button>
									<Button
										text='Militaire du rang'
										type={this.state.categoryId == '1' ? ButtonType.Primary : ButtonType.Secondary}
										size={ButtonSize.Medium}
										onClick={() => this.setfilter('1', '1')}></Button>
									<Button
										text='Sous-officier'
										type={this.state.categoryId == '2' ? ButtonType.Primary : ButtonType.Secondary}
										size={ButtonSize.Medium}
										onClick={() => this.setfilter('1', '2')}></Button>
									<Button
										text='Officier'
										type={this.state.categoryId == '3' ? ButtonType.Primary : ButtonType.Secondary}
										size={ButtonSize.Medium}
										onClick={() => this.setfilter('1', '3')}></Button>
									<div className='card-container row-2-elem'>{this.buildArmy(EnumArmy.ADT)}</div>
								</TabItem>
								<TabItem
									id='army2'
									tabIndex={1}
									selected={true}
									mobileOnly={false}
									noBackroundGray={true}>
									<Button
										text='Tous'
										type={
											this.state.categoryId != '4' && this.state.categoryId != '5'
												? ButtonType.Primary
												: ButtonType.Secondary
										}
										size={ButtonSize.Medium}
										onClick={() => this.setfilter('2', '')}></Button>
									<Button
										text='Non-officier'
										type={this.state.categoryId == '4' ? ButtonType.Primary : ButtonType.Secondary}
										size={ButtonSize.Medium}
										onClick={() => this.setfilter('2', '4')}></Button>
									<Button
										text='Officier'
										type={this.state.categoryId == '5' ? ButtonType.Primary : ButtonType.Secondary}
										size={ButtonSize.Medium}
										onClick={() => this.setfilter('2', '5')}></Button>
									<div className='card-container row-2-elem'>{this.buildArmy(EnumArmy.MN)}</div>
								</TabItem>
								<TabItem
									id='army3'
									tabIndex={2}
									selected={true}
									mobileOnly={false}
									noBackroundGray={true}>
									<Button
										text='Tous'
										type={
											this.state.categoryId != '6' &&
											this.state.categoryId != '7' &&
											this.state.categoryId != '8'
												? ButtonType.Primary
												: ButtonType.Secondary
										}
										size={ButtonSize.Medium}
										onClick={() => this.setfilter('3', '')}></Button>
									<Button
										text='Militaires du Rang'
										type={this.state.categoryId == '6' ? ButtonType.Primary : ButtonType.Secondary}
										size={ButtonSize.Medium}
										onClick={() => this.setfilter('3', '6')}></Button>
									<Button
										text='Sous-officier'
										type={this.state.categoryId == '7' ? ButtonType.Primary : ButtonType.Secondary}
										size={ButtonSize.Medium}
										onClick={() => this.setfilter('3', '7')}></Button>
									<Button
										text='Officiers'
										type={this.state.categoryId == '8' ? ButtonType.Primary : ButtonType.Secondary}
										size={ButtonSize.Medium}
										onClick={() => this.setfilter('3', '8')}></Button>
									<div className='card-container row-2-elem'>{this.buildArmy(EnumArmy.AAE)}</div>
								</TabItem>
							</Tabs>
						</div>
					</div>
				</div>
			</>
		);
	}
}

export default Vitrines;
