import { BrowserView, MobileView } from 'react-device-detect';
import Alert, { AlertSize, AlertType } from '../../components/alert/Alert';
import BaseComponent, { IProfileState } from '../../components/BaseComponent';
import Button, { ButtonIconState, ButtonSize, ButtonType } from '../../components/button/Button';
import Container from '../../components/container/Container';
import FormContainer from '../../components/form-container/FormContainer';
import Input, { InputType } from '../../components/input/Input';
import PageTitle from '../../components/page-title/PageTitle';
import ReferentialSelect from '../../components/referential-select/ReferentialSelect';
import EnumGroupingType from '../../enums/EnumGroupingType';
import ReferentialsService, { ReferentialType } from '../../services/ReferentialsService';
import NationSelect from '../../components/referential-select/NationSelect';

interface INationaliteState extends IProfileState {
	toggleNationalityOne?: boolean;
	toggleNationalityTwo?: boolean;
}

class Nationalite extends BaseComponent<INationaliteState> {
	state: INationaliteState = { isEdit: false };
	nationaliteActuelle: any = {};
	nationaliteNaissance: any = {};
	disableFranceYears: boolean = true;

	groupingType: number = EnumGroupingType.SECURITE_NATIONALITE;

	constructor(props: any) {
		super(props);
		this.registerGetHook(async () => {
			await ReferentialsService.getReferential(
				ReferentialType.NATIONALITY,
				this.state.profile?.personal?.identity?.nationalities?.currentNationalityId ?? 0
			).then((x) => {
				this.nationaliteActuelle = x.data;
			});
			await ReferentialsService.getReferential(
				ReferentialType.NATIONALITY,
				this.state.profile?.personal?.identity?.birthCountryId ?? 0
			).then((x) => {
				this.nationaliteNaissance = x.data;
				this.disableFranceYears = this.nationaliteNaissance?.name?.toLowerCase() === 'france';
				this.setState({
					toggleNationalityOne: this.state.profile?.personal?.identity?.nationalities?.otherNationalityIdOne !== 0,
					toggleNationalityTwo: this.state.profile?.personal?.identity?.nationalities?.otherNationalityIdTwo !== 0
				});
			});
		});
	}

	render() {
		return (
			<Container isLoading={this.state.isLoading}>
				<div className='fr-grid-row fr-grid-row--center fr-grid-row--gutters'>
					<div className='fr-col-11 fr-col-lg-8'>
						<PageTitle
							title='Nationalité'
							description='Certains champs concernant vos informations personnelles sont automatiquement remplies. Veuillez vérifier l’exactitude des informations et compléter les informations manquantes.'
						/>
					</div>
				</div>
				<div className='fr-grid-row fr-grid-row--center fr-grid-row--gutters'>
					<div className='fr-col-12 fr-col-lg-8'>
						{this.state.isSuccess && this.state.groupingIsValidate == false && (
							<Alert
								description='Les informations renseignées ont été enregistrées avec succès'
								hasCloseButton={true}
								size={AlertSize.Small}
								type={AlertType.Success}
								isShown={this.state.isSuccess}
								onHide={() => this.setState({ isSuccess: false })}
							/>
						)}
						{this.state.isGroupingValidationError && this.state.groupingIsValidate == true && (
							<Alert
								description="Vos modifications n'ont pas été prises en compte, car vos données ont déjà été validées."
								hasCloseButton={true}
								size={AlertSize.Small}
								type={AlertType.Error}
								isShown={this.state.isGroupingValidationError}
								onHide={() => this.setState({ isSuccess: false, isGroupingValidationError: false })}
							/>
						)}
					</div>
				</div>
				<div className='fr-grid-row fr-grid-row--center'>
					<div className='fr-col-12 fr-col-lg-8'>
						<FormContainer>
							<div className='fr-grid-row'>
								<div className='fr-col-12'>
									<Input
										title='Nationalité actuelle'
										value={this.nationaliteActuelle?.nationality}
										type={InputType.Text}
										disabled={true}></Input>
								</div>
							</div>
							<div className='fr-grid-row'>
								<div className='fr-col-12'>
									<Input
										title='Nationalité de naissance'
										value={this.nationaliteNaissance?.nationality}
										type={InputType.Text}
										disabled={true}></Input>
								</div>
							</div>
							<div
								className='fr-grid-row'
								style={{ marginBottom: '1rem' }}>
								<div className='fr-col-12'>
									<NationSelect
										label='Autre nationalité'
										value={
											this.state.profile?.personal?.identity?.nationalities?.otherNationalityIdOne?.toString() !==
											'0'
												? this.state.profile?.personal?.identity?.nationalities?.otherNationalityIdOne?.toString()
												: ''
										}
										type={ReferentialType.NATIONALITY}
										disabled={
											!this.state.toggleNationalityOne &&
											this.state.profile?.personal?.identity?.nationalities?.otherNationalityIdOne === 0
										}
										customOptionLabel={(o: any) => o.formattedNationality}
										onChange={(e) => {
											this.setState({
												profile: {
													...this.state.profile,
													personal: {
														...this.state.profile?.personal,
														identity: {
															...this.state.profile?.personal?.identity,
															nationalities: {
																...this.state.profile?.personal?.identity?.nationalities,
																otherNationalityIdOne: e?.id
															}
														}
													}
												}
											});
										}}></NationSelect>
									{(this.state.toggleNationalityTwo ||
										this.state.profile?.personal?.identity?.nationalities?.otherNationalityIdTwo !== 0) && (
										<NationSelect
											label='Autre nationalité'
											value={
												this.state.profile?.personal?.identity?.nationalities?.otherNationalityIdTwo?.toString() !==
												'0'
													? this.state.profile?.personal?.identity?.nationalities?.otherNationalityIdTwo?.toString()
													: ''
											}
											type={ReferentialType.NATIONALITY}
											customOptionLabel={(o: any) => o.formattedNationality}
											onChange={(e) => {
												this.setState({
													profile: {
														...this.state.profile,
														personal: {
															...this.state.profile?.personal,
															identity: {
																...this.state.profile?.personal?.identity,
																nationalities: {
																	...this.state.profile?.personal?.identity?.nationalities,
																	otherNationalityIdTwo: e?.id
																}
															}
														}
													}
												});
											}}></NationSelect>
									)}
									<Button
										disabled={this.state.toggleNationalityOne && this.state.toggleNationalityTwo}
										size={ButtonSize.Small}
										text='Ajouter une nationalité'
										iconClassName='fr-icon-add-line'
										iconState={ButtonIconState.Left}
										type={ButtonType.Tertiary}
										onClick={() => {
											this.setState({
												toggleNationalityTwo: this.state.toggleNationalityOne,
												toggleNationalityOne: true
											});
										}}></Button>
								</div>
							</div>
							<div className='fr-grid-row'>
								<div className='fr-col-12'>
									<Input
										title="Année d'acquisition de la nationalité française"
										value={
											this.state.profile?.personal?.identity?.nationalities?.obtainingFrenchNationalityYear?.toString() !==
											'0'
												? this.state.profile?.personal?.identity?.nationalities?.obtainingFrenchNationalityYear?.toString()
												: ''
										}
										type={InputType.Text}
										disabled={this.disableFranceYears}
										onChange={(e) => {
											var regex = RegExp('^[0-9]*$');
											if (!regex.test(e) || e.length > 4) {
												return;
											}
											this.setState({
												profile: {
													...this.state.profile,
													personal: {
														...this.state.profile?.personal,
														identity: {
															...this.state.profile?.personal?.identity,
															nationalities: {
																...this.state.profile?.personal?.identity?.nationalities,
																obtainingFrenchNationalityYear: +e
															}
														}
													}
												}
											});
										}}></Input>
								</div>
							</div>
							<div className='fr-grid-row'>
								<div className='fr-col-12'>
									<Input
										title="Année d'arrivée en France"
										value={
											this.state.profile?.personal?.identity?.nationalities?.arrivalInFrance?.toString() !==
											'0'
												? this.state.profile?.personal?.identity?.nationalities?.arrivalInFrance?.toString()
												: ''
										}
										type={InputType.Text}
										disabled={this.disableFranceYears}
										onChange={(e) => {
											var regex = RegExp('^[0-9]*$');
											if (!regex.test(e) || e.length > 4) {
												return;
											}
											this.setState({
												profile: {
													...this.state.profile,
													personal: {
														...this.state.profile?.personal,
														identity: {
															...this.state.profile?.personal?.identity,
															nationalities: {
																...this.state.profile?.personal?.identity?.nationalities,
																arrivalInFrance: +e
															}
														}
													}
												}
											});
										}}></Input>
								</div>
							</div>
							<BrowserView>
								{!this.state.groupingIsValidate && (
									<div className='fr-grid-row fr-grid-row--right'>
										<div className='fr-col-12 fr-col-md-6 fr-align-right'>
											<Button
												className='no-margin'
												disabled={false}
												text='Enregistrer mes informations'
												type={ButtonType.Primary}
												size={ButtonSize.Medium}
												modalId=''
												onClick={() => {
													this.updateProfile(() => this.displaySuccess());
												}}></Button>
										</div>
									</div>
								)}
							</BrowserView>
						</FormContainer>
						<MobileView>
							{!this.state.groupingIsValidate && (
								<div className='fr-grid-row fr-grid-row--center'>
									<div className='fr-col-8'>
										<Button
											className='mobile-button'
											disabled={false}
											text='Enregistrer mes informations'
											type={ButtonType.Primary}
											onClick={() => {
												this.updateProfile(() => this.displaySuccess());
											}}
											size={ButtonSize.Medium}
											modalId=''></Button>
									</div>
								</div>
							)}
						</MobileView>
					</div>
				</div>
			</Container>
		);
	}
}
export default Nationalite;
