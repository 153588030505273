import BaseComponent, { IProfileState } from '../../components/BaseComponent';
import Button, { ButtonIconState, ButtonSize, ButtonType } from '../../components/button/Button';
import Container from '../../components/container/Container';
import FormContainer from '../../components/form-container/FormContainer';
import Input, { InputType } from '../../components/input/Input';
import Modal, { ModalSize } from '../../components/modal/Modal';
import PageTitle from '../../components/page-title/PageTitle';
import Select, { SelectItem } from '../../components/select/Select';
import { v4 as uuid } from 'uuid';
import TileContainer from '../../components/tile-container/TileContainer';
import Tile from '../../components/tile/Tile';
import { NephosESLScopPoolFormationDetail } from '../../sparta.api';
import ReferentialsService, { ReferentialType } from '../../services/ReferentialsService';
import AttachmentUpload from '../../components/attachment-upload/AttachmentUpload';
import { AttachmentState } from '../../services/AttachmentService';
import Alert, { AlertSize, AlertType } from '../../components/alert/Alert';
import EnumGroupingType from '../../enums/EnumGroupingType';
import ReferentialSelect from '../../components/referential-select/ReferentialSelect';
import Radio from '../../components/radio/Radio';
import DateService from '../../services/DateService';
import { BrowserView } from 'react-device-detect';
import { FieldCheckerService } from '../../services';
import EnumApplicantInputArmyType from '../../enums/EnumApplicantInputArmyType';
import { createRef } from 'react';
interface ICompetencesDiplomesFormationsState extends IProfileState {
	currentFormationDetail?: NephosESLScopPoolFormationDetail;
	isFormationLoading?: boolean;
	isYearsLoading?: boolean;
	successMessage?: string;
	isUploadFormationDetail: boolean;
	isDeleteFormationDetail: boolean;
	isUploadPixCertificate: boolean;
	isDeletePixCertificate: boolean;
}

class CompetencesDiplomesFormations extends BaseComponent<ICompetencesDiplomesFormationsState> {
	state: ICompetencesDiplomesFormationsState = {
		isFormationLoading: false,
		isEdit: false,
		isDeleteFormationDetail: false,
		isUploadFormationDetail: false,
		isUploadPixCertificate: false,
		isDeletePixCertificate: false
	};
	inputRef: any = createRef();
	inputFormationPixRef: any = createRef();
	certificateModelId: string = uuid();
	currentFormationIndex: number = -1;
	yearsSelectItems: SelectItem[] = [];
	pixModalId: string = uuid();
	groupingType: number = EnumGroupingType.COMPETENCES_DIPLOMES;

	async componentDidMount() {
		super.componentDidMount();

		ReferentialsService.getYears().then((years) => {
			this.yearsSelectItems = years.map((x) => {
				return { DisplayName: x.name, Value: x.name };
			});
			this.setState({ isYearsLoading: false });
		});
	}

	async checkIfCanDelete() {
		return await this.getRequiredFields();
	}

	updateCertificate() {
		this.setState({
			successMessage: 'Certification modifiée avec succès'
		});
		this.updateProfile(() => {
			this.displaySuccess();
			this.initUpload();
			setTimeout(() => {
				this.inputRef.current.disabled = false;
			}, 2000);
		});
	}

	setPixCertificate(guid?: string) {
		this.setState({
			profile: {
				...this.state.profile,
				education: {
					...this.state.profile?.education,
					pixCertificate: {
						id: guid,
						validationState: AttachmentState.ADDED
					}
				}
			},
			isUploadPixCertificate: true
		});
	}

	deletePixCertificate() {
		this.setState({
			profile: {
				...this.state.profile,
				education: {
					...this.state.profile?.education,
					pixCertificate: undefined
				}
			},
			isDeletePixCertificate: true
		});
	}

	initUpload() {
		this.setState({
			isUploadPixCertificate: false,
			isDeletePixCertificate: false
		});
	}

	render() {
		return (
			<Container isLoading={this.state.isLoading}>
				<div className='fr-grid-row fr-grid-row--center fr-grid-row--gutters'>
					<div className='fr-col-11 fr-col-lg-8'>
						<PageTitle
							title='Diplômes et formations'
							description='Veuillez remplir les informations demandées ci-dessous'
						/>
					</div>
				</div>
				<div className='fr-grid-row fr-grid-row--center fr-grid-row--gutters'>
					<div className='fr-col-12 fr-col-lg-8'>
						{this.state.isSuccess && this.state.successMessage && this.state.groupingIsValidate == false && (
							<Alert
								description={this.state.successMessage}
								hasCloseButton={true}
								size={AlertSize.Small}
								type={AlertType.Success}
								isShown={this.state.isSuccess}
								onHide={() => this.setState({ isSuccess: false })}
							/>
						)}
						{this.state.isGroupingValidationError && this.state.groupingIsValidate == true && (
							<Alert
								description="Vos modifications n'ont pas été prises en compte, car vos données ont déjà été validées."
								hasCloseButton={true}
								size={AlertSize.Small}
								type={AlertType.Error}
								isShown={this.state.isGroupingValidationError}
								onHide={() => this.setState({ isSuccess: false, isGroupingValidationError: false })}
							/>
						)}
					</div>
				</div>
				<div className='fr-grid-row fr-grid-row--center'>
					<div className='fr-col-12 fr-col-lg-8'>
						<FormContainer>
							<h5>Mes qualifications et formations</h5>
							<p>
								Pour ajouter une qualification et/ou formation à votre dossier de candidature, veuillez cliquer
								sur le bouton “Ajouter un diplôme”
							</p>
							<Button
								disabled={this.state.groupingIsValidate ?? false}
								size={ButtonSize.Medium}
								text='Ajouter un diplôme'
								type={ButtonType.Primary}
								modalId={this.certificateModelId}
								onClick={() => {
									this.currentFormationIndex = -1;
									this.setState(
										{
											isEdit: false,
											isFormationLoading: true,
											currentFormationDetail: {},
											isDeleteFormationDetail: false,
											isUploadFormationDetail: false
										},
										() => {
											this.setState({ isFormationLoading: false });
										}
									);
								}}></Button>
							{this.state.profile?.education?.formationDetails &&
								this.state.profile?.education?.formationDetails.length > 0 && (
									<TileContainer>
										{this.state.profile?.education?.formationDetails.map((x, i) => {
											return (
												<Tile
													key={x.name + '' + i}
													title={x.name || ''}
													description={
														this.state.groupingIsValidate ?? false ? '' : 'En cours de validation'
													}
													disabled={this.state.groupingIsValidate ?? false}
													onDelete={async () => {
														let isGroupingValidated = await this.checkIfCanDelete();
														if (!isGroupingValidated) {
															let newdetails = this.state.profile?.education?.formationDetails!;
															newdetails = [
																...newdetails?.slice(0, i),
																...newdetails?.slice(i + 1, newdetails?.length)
															];
															this.setState(
																{
																	successMessage: 'Le diplôme a été supprimé avec succès',
																	profile: {
																		...this.state.profile,
																		education: {
																			...this.state.profile?.education,
																			formationDetails: newdetails
																		}
																	}
																},
																this.updateProfile
															);
														}
													}}
													modalId={this.certificateModelId}
													onEdit={() => {
														this.currentFormationIndex = i;
														this.setState(
															{
																isEdit: true,
																isFormationLoading: true,
																currentFormationDetail:
																	this.state.profile?.education?.formationDetails?.at(i),
																isDeleteFormationDetail: false,
																isUploadFormationDetail: false
															},
															() => {
																this.setState({ isFormationLoading: false });
															}
														);
														this.setState({ isFormationLoading: false });
													}}
												/>
											);
										})}
									</TileContainer>
								)}
							<>
								<hr className='fr-hr' />
								<h5>Certification PIX</h5>
								<Radio
									name='pixcertification'
									title='Avez-vous une certification PIX ?'
									isHorizontal={false}
									items={[
										{
											DisplayName: 'Oui',
											Value: 1,
											Checked: this.state.isPixCertification === true
										},
										{
											DisplayName: 'Non',
											Value: 0,
											Checked: this.state.isPixCertification === false
										}
									]}
									disabled={this.state.groupingIsValidate ?? false}
									isSmall={false}
									errorMessage=''
									onChange={(e) => {
										this.setState({
											isPixCertification: e.currentTarget.value === '1',
											profile: {
												...this.state.profile,
												education: {
													...this.state.profile?.education,
													pixCertification: e.currentTarget.value === '1'
												}
											}
										});
									}}></Radio>
								{this.state.isPixCertification && (
									<>
										{this.state.profile?.education?.pixLevel === undefined ||
											(this.state.profile?.education?.pixLevel == 0 && (
												<Button
													disabled={this.state.groupingIsValidate ?? false}
													iconState={ButtonIconState.Left}
													text='Ajouter ma certification'
													type={ButtonType.Primary}
													size={ButtonSize.Medium}
													modalId={this.pixModalId}
													onClick={() => {
														this.currentFormationIndex = -1;
														this.setState({
															isEdit: false
														});
													}}></Button>
											))}
										{this.state.profile?.education?.pixLevel !== undefined &&
											this.state.profile?.education?.pixLevel != 0 && (
												<TileContainer>
													<Tile
														key={'pixLevel'}
														title={'PIX - Niveau ' + this.state.profile?.education?.pixLevel}
														description={
															this.state.groupingIsValidate ?? false ? '' : 'En cours de validation'
														}
														modalId={this.pixModalId}
														onEdit={() => {
															this.setState({
																isEdit: true
															});
														}}
														onDelete={async () => {
															let isGroupingValidated = await this.checkIfCanDelete();
															if (!isGroupingValidated) {
																this.setState(
																	{
																		profile: {
																			...this.state.profile,
																			education: {
																				...this.state.profile?.education,
																				pixLevel: 0,
																				pixDate: undefined
																			}
																		}
																	},
																	() => this.updateProfile()
																);
															}
														}}
													/>
												</TileContainer>
											)}
									</>
								)}
							</>
							<Modal
								inputRef={this.inputRef}
								title={this.state.isEdit ? 'Modifier un diplome' : 'Ajouter un diplome'}
								id={this.certificateModelId}
								size={ModalSize.Large}
								buttons={[
									{
										Disabled:
											this.state.currentFormationDetail?.level == '0' ||
											this.state.currentFormationDetail?.name === undefined ||
											this.state.currentFormationDetail?.name.trim() === '',
										DisplayName: this.state.isEdit ? 'Modifier un diplome' : 'Ajouter un diplôme',
										OnClick: () => {
											if (this.inputRef && this.inputRef.current && !this.inputRef.current.disabled) {
												this.inputRef.current.disabled = true;
												let newdetails = this.state.profile?.education?.formationDetails || [];
												if (this.currentFormationIndex === -1 && this.state.currentFormationDetail) {
													newdetails.push(this.state.currentFormationDetail);
												} else if (this.state.currentFormationDetail) {
													newdetails[this.currentFormationIndex] = this.state.currentFormationDetail;
												}
												this.setState(
													{
														successMessage: this.state.isEdit
															? 'Le diplôme a été modifié avec succès'
															: 'Le diplôme a été ajouté avec succès',
														profile: {
															...this.state.profile,
															education: {
																...this.state.profile?.education,
																formationDetails: newdetails
															}
														},
														isDeleteFormationDetail: false,
														isUploadFormationDetail: false
													},
													() => {
														this.updateProfile();
														setTimeout(() => {
															this.inputRef.current.disabled = false;
														}, 2000);
													}
												);
											}
										}
									}
								]}>
								<>
									{!this.state.isFormationLoading && (
										<>
											<Input
												title='Nom de la qualification ou formation'
												type={InputType.Text}
												value={this.state.currentFormationDetail?.name}
												onChange={(v) => {
													this.setState({
														currentFormationDetail: {
															...this.state.currentFormationDetail,
															name: v
														}
													});
												}}></Input>
											{!this.state.isYearsLoading && (
												<Select
													title="Année d'obtention"
													disabled={false}
													defaultOptionDisplayName='Sélectionnez'
													value={this.state.currentFormationDetail?.validationYear}
													items={this.yearsSelectItems}
													onChange={(v) => {
														this.setState({
															currentFormationDetail: {
																...this.state.currentFormationDetail,
																validationYear: parseInt(v)
															}
														});
													}}></Select>
											)}
											<ReferentialSelect
												type={ReferentialType.DEGREE}
												label='Niveau du diplôme'
												value={this.state.currentFormationDetail?.level}
												onChange={(e) => {
													this.setState({
														currentFormationDetail: {
															...this.state.currentFormationDetail,
															level: e?.id
														}
													});
												}}></ReferentialSelect>
											<AttachmentUpload
												title='Pièce jointe'
												hintText=''
												isDisabled={this.state.groupingIsValidate ?? false}
												guid={this.state.currentFormationDetail?.attachmentId?.id}
												validationState={this.state.currentFormationDetail?.attachmentId?.validationState}
												onUpload={(guid) => {
													this.setState({
														currentFormationDetail: {
															...this.state.currentFormationDetail,
															attachmentId: {
																id: guid,
																validationState: AttachmentState.ADDED
															}
														},
														isUploadFormationDetail: true
													});
												}}
												onDelete={() => {
													this.setState({
														currentFormationDetail: {
															...this.state.currentFormationDetail,
															attachmentId: undefined
														},
														isDeleteFormationDetail: true
													});
												}}
												isUploadInProgress={this.state.isUploadFormationDetail}
												isDeleteInProgress={this.state.isDeleteFormationDetail}></AttachmentUpload>
										</>
									)}
								</>
							</Modal>
							<Modal
								inputRef={this.inputFormationPixRef}
								title={this.state.isEdit ? 'Modifier ma certification' : 'Ajouter ma certification'}
								id={this.pixModalId}
								size={ModalSize.Large}
								buttons={[
									{
										DisplayName: this.state.isEdit ? 'Modifier ma certification' : 'Ajouter ma certification',
										OnClick: () => {
											if (this.inputRef && this.inputRef.current && !this.inputRef.current.disabled) {
												this.inputRef.current.disabled = true;
												this.updateCertificate.bind(this);
											}
										}
									}
								]}>
								<>
									<Input
										type={InputType.Number}
										title='Niveau PIX'
										value={this.state.profile?.education?.pixLevel?.toString() || '0'}
										onChange={(e) => {
											this.setState({
												profile: {
													...this.state.profile,
													education: {
														...this.state.profile?.education,
														pixLevel: parseInt(e)
													}
												}
											});
										}}
									/>
									<Input
										type={InputType.Date}
										title="Date d'obtention"
										value={DateService.Convert(this.state.profile?.education?.pixDate || '') || ''}
										onChange={(e) => {
											this.setState({
												profile: {
													...this.state.profile,
													education: {
														...this.state.profile?.education,
														pixDate: e
													}
												}
											});
										}}
									/>
									{ReferentialsService.getArmy() === 3 && (
										<AttachmentUpload
											noExplanation
											hintText='Attestation PIX'
											guid={this.state.profile?.education?.pixCertificate?.id}
											onUpload={this.setPixCertificate.bind(this)}
											onDelete={this.deletePixCertificate.bind(this)}
											isDisabled={this.state.groupingIsValidate ?? false}
											isUploadInProgress={this.state.isUploadPixCertificate}
											isDeleteInProgress={this.state.isDeletePixCertificate}
										/>
									)}
								</>
							</Modal>
							{!this.state.groupingIsValidate && (
								<div className='fr-grid-row fr-grid-row--right'>
									<div className='fr-col-12 fr-col-md-6 fr-align-right'>
										<br></br>
										<Button
											text='Enregistrer mes informations'
											disabled={this.state.groupingIsValidate ?? false}
											size={ButtonSize.Medium}
											type={ButtonType.Primary}
											onClick={() => {
												if (!this.state.isPixCertification) {
													this.setState(
														{
															successMessage:
																'Les informations renseignées ont été enregistrées avec succès',
															profile: {
																...this.state.profile,
																education: {
																	...this.state.profile?.education,
																	pixCertification: false,
																	pixLevel: 0,
																	pixDate: undefined,
																	pixCertificate: undefined
																}
															}
														},
														this.updateProfile
													);
												} else {
													this.updateProfile(() => {
														this.initUpload();
													});
												}
											}}></Button>
									</div>
								</div>
							)}
						</FormContainer>
					</div>
				</div>
			</Container>
		);
	}
}
export default CompetencesDiplomesFormations;
