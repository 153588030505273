import StepContainer from '../../components/step/StepContainer2';
import Container from '../../components/container/Container';
import FormContainer from '../../components/form-container/FormContainer';
import PageTitle from '../../components/page-title/PageTitle';
import IdentifiantConnexionStep from '../../components/step/accountCreation/IdentifiantConnexionStep';
import PersonnalInformationStep from '../../components/step/accountCreation/PersonnalInformationStep';
import CirfaStep from '../../components/step/common/CirfaStep';
import PasswordStep from '../../components/step/accountCreation/PasswordStep';
import ConfirmationEmailStep from '../../components/step/accountCreation/ConfirmationEmailStep';
import Account from '../../models/Account';
import EligibilityStep from '../../components/step/accountCreation/EligibilityStep';
import { useEffect, useState } from 'react';
import ReferentialsService, { ReferentialType } from '../../services/ReferentialsService';
import {
	QueryStringService,
	DateService,
	FormatCheckService,
	StepService,
	AccountPublicService,
	PasswordPublicService
} from '../../services';
import { AccountCreateStatus } from '../../services/public/AccountPublicService';
import { Navigate } from 'react-router-dom';
import { Step } from '../../models';

interface Props {
	location: Location;
}

function AccountCreationFunc({ location }: Props) {
	const [forceNextStep, setForceNextStep] = useState(0);
	const [forcePreviousStep, setForcePreviousStep] = useState(-1);
	const [army, setArmy] = useState<any>();
	const [accountCreated, setAccountCreated] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [newAccount, setNewAccount] = useState<Account>({
		email: '',
		acceptance: false,
		hasSpartaAccount: false,
		hasApplicantAccount: false,
		hasFrenchNationality: undefined,
		birthDate: undefined,
		genderId: undefined,
		birthLastname: '',
		birthFirstname: '',
		phoneNumber: '',
		cirfaCategoryId: 0,
		zipCode: undefined,
		cirfaId: 0,
		password: '',
		confirmPassword: '',
		validationPassword: false,
		isValidCapcha: false,
		isConfirmationCodeValid: false,
		verificationCode: '',
		recaptchaResponse: undefined
	});

	useEffect(() => {
		document.title = 'Espace Candidat - Création de compte';
		setIsLoading(true);
		QueryStringService.initialiseLocalStorage(location);
		setNewAccount((oldValue) => ({ ...oldValue, cirfaCategoryId: +(localStorage.getItem('jobCategoryId') || 0) }));
		ReferentialsService.getReferentialArmy()
			.then((army) => {
				setArmy(army);
				setIsLoading(false);
			})
			.catch((error) => {
				setIsLoading(false);
			});
	}, []);

	const handleAccountChange = (evt: any): void => {
		if (evt.target) {
			const key = evt.target.name as string;
			if (evt.target.type == 'radio') {
				if (key === 'genderId') {
					setNewAccount((oldValue) => ({ ...oldValue, [key]: +evt.target?.value }));
				}
				if (key === 'hasFrenchNationality') {
					setNewAccount((oldValue) => ({ ...oldValue, [key]: evt.target?.value == 'true' }));
				}
			} else {
				const valueInput = evt.target.type == 'checkbox' ? evt.target.checked : evt.target.value;
				setNewAccount((oldValue) => ({ ...oldValue, [key]: valueInput }));
				if (key == 'cirfaId') {
					setForceNextStep((value) => value + 1);
				}
			}
		} else {
			if (evt.name == 'cirfaCategoryId') {
				setNewAccount((oldValue) => ({ ...oldValue, [evt.name]: +evt?.value }));
			} else {
				setNewAccount((oldValue) => ({ ...oldValue, [evt.name]: evt.value }));
			}
		}
	};

	const validStep1 = (): boolean => {
		return (
			newAccount.acceptance &&
			newAccount.email != '' &&
			FormatCheckService.checkEmail(newAccount.email) &&
			!newAccount.hasApplicantAccount
		);
	};

	const validStep2 = (): boolean => {
		let valid = true;

		if (
			newAccount.hasFrenchNationality === undefined ||
			(newAccount.hasFrenchNationality !== undefined && newAccount.hasFrenchNationality == false)
		) {
			valid = false;
		}

		if (
			newAccount.birthDate === undefined ||
			(newAccount.birthDate !== undefined && DateService.getAgeFromString(newAccount.birthDate) < army?.minAge) ||
			(newAccount.birthDate !== undefined &&
				DateService.getAgeFromString(newAccount.birthDate) > +(localStorage.getItem('maxAge') || army?.maxAge))
		) {
			valid = false;
		}

		return valid;
	};

	const validStep3 = (): boolean => {
		const validgenderId = newAccount.genderId !== undefined;
		const validBirthbirthLastname =
			newAccount.birthLastname !== undefined &&
			newAccount.birthLastname.length !== 0 &&
			FormatCheckService.checkLastName(newAccount.birthLastname);
		const validBirthFirstname =
			newAccount.birthFirstname !== undefined &&
			newAccount.birthFirstname.length !== 0 &&
			FormatCheckService.checkFirstName(newAccount.birthFirstname);
		const validphoneNumber =
			newAccount.phoneNumber !== undefined &&
			newAccount.phoneNumber.length !== 0 &&
			FormatCheckService.checkPhoneNumber(newAccount.phoneNumber);
		return validgenderId && validBirthbirthLastname && validBirthFirstname && validphoneNumber;
	};

	const validStep4 = (): boolean => {
		if (newAccount.isValidCapcha) {
			setNewAccount((oldValue) => ({ ...oldValue, isValidCapcha: false }));
		}
		return false;
	};
	const validStep5 = (): boolean => {
		return (
			newAccount.password != undefined &&
			PasswordPublicService.verify(newAccount.password) &&
			newAccount.password == newAccount.confirmPassword &&
			(newAccount.isValidCapcha || process.env.REACT_APP_RECAPTCHA_ACCOUNT_CREATION_KEY === undefined)
		);
	};
	const validStep6 = (): boolean => {
		if (newAccount.verificationCode != '' && newAccount.isConfirmationCodeValid == true) {
			handleSubmitForm();
		}
		return false;
	};

	const step1: Step = StepService.BuildStep(
		'Vérification de votre identifiant de connexion',
		<IdentifiantConnexionStep
			model={newAccount}
			handleChange={handleAccountChange}
		/>,
		validStep1
	);
	const step2: Step = StepService.BuildStep(
		'Vérification de votre éligibilité',
		<EligibilityStep
			model={newAccount}
			handleChange={handleAccountChange}
		/>,
		validStep2
	);
	const step3: Step = StepService.BuildStep(
		'Vérification de vos informations personnelles',
		<PersonnalInformationStep
			model={newAccount}
			handleChange={handleAccountChange}
		/>,
		validStep3
	);
	const step4: Step = StepService.BuildStep(
		'Sélection de votre centre de recrutement (CIRFA) de référence',
		<CirfaStep
			model={newAccount}
			handleChange={handleAccountChange}
		/>,
		validStep4
	);
	const step5: Step = StepService.BuildStep(
		'Création du mot de passe',
		<PasswordStep
			model={newAccount}
			handleChange={handleAccountChange}
		/>,
		validStep5
	);
	const step6: Step = StepService.BuildStep(
		'Vérification de votre adresse électronique',
		<ConfirmationEmailStep
			model={newAccount}
			handleChange={handleAccountChange}
		/>,
		validStep6,
		true,
		true
	);

	const stepsNotHasSpartaAccount = [step1, step2, step3, step4, step5, step6];
	const stepsHasSpartaAccount = [step1, step4, step5, step6];

	const handleSubmitForm = () => {
		setIsLoading(true);
		AccountPublicService.createAccount({
			birthDate: newAccount.birthDate,
			birthFirstname: newAccount.birthFirstname,
			birthLastname: newAccount.birthLastname,
			email: newAccount.email,
			confirmationCode: newAccount.verificationCode,
			frenchNationality: newAccount.hasFrenchNationality,
			password: newAccount.password,
			passwordConfirmation: newAccount.confirmPassword,
			phoneNumber: newAccount.phoneNumber,
			rgpdAcceptance: newAccount.acceptance,
			armyId: ReferentialsService.getArmy(),
			jobCategoryId: +(localStorage.getItem('jobCategoryId') || 0),
			jobTitle: localStorage.getItem('jobTitle') || undefined,
			originId: +(localStorage.getItem('originId') || 0),
			originURL: localStorage.getItem('originURL') || undefined,
			cirfaId: newAccount.cirfaId,
			genderId: newAccount.genderId!
		})
			.then((resp: AccountCreateStatus) => {
				if (resp === AccountCreateStatus.SUCCESS) {
					setAccountCreated(true);
					setIsLoading(false);
				}
			})
			.catch((e) => {
				setIsLoading(false);
			});
	};

	const reInitilized = () => {
		setForcePreviousStep(-1);
	};

	return (
		<>
			{accountCreated && (
				<Navigate
					replace
					to='/login'
				/>
			)}
			<Container isLoading={isLoading}>
				{localStorage.getItem('jobTitle') && (
					<div className='fr-grid-row fr-grid-row--center fr-grid-row--gutters'>
						<div
							className='fr-col-11 fr-col-lg-8'
							style={{ paddingTop: '2rem' }}>
							<PageTitle
								title={localStorage.getItem('jobTitle') || ''}
								description='Créez un compte pour pouvoir postuler à cette offre et compléter votre dossier de candidature'
							/>
						</div>
					</div>
				)}
				<div className='fr-grid-row fr-grid-row--center fr-grid-row--gutters'>
					<div className='fr-col-11 fr-col-lg-8'>
						<FormContainer>
							<StepContainer
								steps={newAccount.hasSpartaAccount ? stepsHasSpartaAccount : stepsNotHasSpartaAccount}
								model={newAccount}
								forceNextStep={forceNextStep}
								forcePreviousStep={forcePreviousStep}
								reInitilized={reInitilized}
								submitForm={() => handleSubmitForm()}
							/>
						</FormContainer>
					</div>
				</div>
			</Container>
		</>
	);
}

export default AccountCreationFunc;
