import QueryString from 'qs';

class QueryStringService {
	public initialiseLocalStorage(location: Location) {
		this.cleanLocalStorage();
		const parsedQueryString = QueryString.parse(location.search, { ignoreQueryPrefix: true });
		this.init_army_origin(parsedQueryString);
		this.init_job_title(parsedQueryString);
		this.init_job_category(parsedQueryString);
		this.init_max_age(parsedQueryString);
		this.init_origin_url(parsedQueryString);
	}

	public initialiseLocalStorageForAppointment(location: Location) {
		this.cleanLocalStorage();
		const parsedQueryString = QueryString.parse(location.search, { ignoreQueryPrefix: true });
		this.verifyToken(parsedQueryString);
		this.init_army_origin(parsedQueryString);
		this.init_job_category(parsedQueryString);
		this.init_job_title(parsedQueryString);
	}

	public initialiseLocalStorageForHeader(location: Location) {
		this.cleanLocalStorage();
		const parsedQueryString = QueryString.parse(location.search, { ignoreQueryPrefix: true });
		this.init_army(parsedQueryString);
	}

	public verifyToken(parsedQueryString: any) {
		if (parsedQueryString.rdv_id !== localStorage.getItem('rdvId')) {
			localStorage.removeItem('auth');
		} else {
			localStorage.removeItem('rdvId');
		}
	}

	public init_army(parsedQueryString: any) {
		if (parsedQueryString.army_id) {
			localStorage.setItem('armyId', parsedQueryString.army_id.toString());
		} else {
			localStorage.removeItem('armyId');
			window.location.href = '/login';
		}
	}

	public init_army_origin(parsedQueryString: any) {
		if (parsedQueryString.army_id && parsedQueryString.origin_id) {
			localStorage.setItem('armyId', parsedQueryString.army_id.toString());
			localStorage.setItem('originId', parsedQueryString.origin_id.toString());
		} else {
			localStorage.removeItem('armyId');
			localStorage.removeItem('originId');
			window.location.href = '/login';
		}
	}

	public init_job_title(parsedQueryString: any) {
		if (parsedQueryString.job_title) localStorage.setItem('jobTitle', parsedQueryString.job_title.toString());
		else localStorage.removeItem('jobTitle');
	}

	public init_job_category(parsedQueryString: any) {
		if (parsedQueryString.job_category_id)
			localStorage.setItem('jobCategoryId', parsedQueryString.job_category_id.toString());
		else localStorage.removeItem('jobCategoryId');
	}

	public init_max_age(parsedQueryString: any) {
		if (parsedQueryString.max_age) localStorage.setItem('maxAge', parsedQueryString.max_age.toString());
		else localStorage.removeItem('maxAge');
	}

	public init_origin_url(parsedQueryString: any) {
		if (parsedQueryString.origin_url) localStorage.setItem('originURL', parsedQueryString.origin_url.toString());
		else localStorage.removeItem('originURL');
	}

	public cleanLocalStorage() {
		localStorage.removeItem('armyId');
		localStorage.removeItem('originId');
		localStorage.removeItem('jobTitle');
		localStorage.removeItem('jobCategoryId');
		localStorage.removeItem('maxAge');
		localStorage.removeItem('originURL');
	}
}

export default new QueryStringService();
