import { BrowserView, MobileView } from 'react-device-detect';
import Alert, { AlertSize, AlertType } from '../../components/alert/Alert';
import BaseComponent, { IProfileState } from '../../components/BaseComponent';
import Button, { ButtonSize, ButtonIconState, ButtonType } from '../../components/button/Button';
import Checkbox from '../../components/checkbox/Checkbox';
import Container from '../../components/container/Container';
import Input, { InputType } from '../../components/input/Input';
import PageTitle from '../../components/page-title/PageTitle';
import Radio from '../../components/radio/Radio';
import { TabHeaderItem } from '../../components/tabs/TabHeader';
import TabItem from '../../components/tabs/TabItem';
import Tabs from '../../components/tabs/Tabs';
import EnumGroupingType from '../../enums/EnumGroupingType';
import DateService from '../../services/DateService';
import { ReferentialType } from '../../services/ReferentialsService';
import NationSelect from '../../components/referential-select/NationSelect';
import ReferentialSelectOptimise from '../../components/referential-select/ReferentialSelectOptimise';

interface IRenseignementsFamilleState extends IProfileState {
	toggleParentOneNationalityOne?: boolean;
	toggleParentOneNationalityTwo?: boolean;
	toggleParentTwoNationalityOne?: boolean;
	toggleParentTwoNationalityTwo?: boolean;
	togglePartnerNationalityOne?: boolean;
	togglePartnerNationalityTwo?: boolean;
	tabItems: TabHeaderItem[];
	showCityFreeFieldsParentOne?: boolean;
	showCityFreeFieldsParentTwo?: boolean;
	showCityFreeFieldsPartner?: boolean;
	showAddressCityFreeFieldsParentOne?: boolean;
	showAddressCityFreeFieldsParentTwo?: boolean;
	showAddressCityFreeFieldsPartner?: boolean;
}

class RenseignementsFamille extends BaseComponent<IRenseignementsFamilleState> {
	state: IRenseignementsFamilleState = {
		tabItems: [
			{
				displayName: 'Père',
				id: 'parent-1',
				tabIndex: 0,
				selected: true
			},
			{
				displayName: 'Mère',
				id: 'parent-2',
				tabIndex: 1,
				selected: false
			},
			{
				displayName: 'Conjoint',
				id: 'conjoint',
				tabIndex: 2,
				selected: false
			}
		],
		isEdit: false
	};

	groupingType = EnumGroupingType.SECURITE_RENSEIGNEMENTS_FAMILLE;

	render() {
		return (
			<Container isLoading={this.state.isLoading}>
				<div className='fr-grid-row fr-grid-row--center fr-grid-row--gutters'>
					<div className='fr-col-11 fr-col-lg-8'>
						<PageTitle
							title='Renseignement sur la famille'
							description='Veuillez compléter les informations ci-dessous. Nous vous informons qu’en cliquant sur le bouton “passer à l’onglet suivant”, les informations renseignées seront enregistrées.'
						/>
					</div>
				</div>
				<div className='fr-grid-row fr-grid-row--center fr-grid-row--gutters'>
					<div className='fr-col-12 fr-col-lg-8'>
						{this.state.isSuccess && this.state.groupingIsValidate == false && (
							<Alert
								description='Les informations renseignées ont été enregistrées avec succès'
								hasCloseButton={true}
								size={AlertSize.Small}
								type={AlertType.Success}
								isShown={this.state.isSuccess}
								onHide={() => this.setState({ isSuccess: false })}
							/>
						)}
						{this.state.isGroupingValidationError && this.state.groupingIsValidate == true && (
							<Alert
								description="Vos modifications n'ont pas été prises en compte, car vos données ont déjà été validées."
								hasCloseButton={true}
								size={AlertSize.Small}
								type={AlertType.Error}
								isShown={this.state.isGroupingValidationError}
								onHide={() => this.setState({ isSuccess: false, isGroupingValidationError: false })}
							/>
						)}
					</div>
				</div>
				<div className='fr-grid-row fr-grid-row--center'>
					<div className='fr-col-12 fr-col-lg-8'>
						<Tabs
							className='tabs-with-overflow'
							mobileOnly={false}
							items={this.state.tabItems}>
							<TabItem
								id='parent-1'
								tabIndex={0}
								selected={true}
								mobileOnly={false}>
								<div className='fr-grid-row'>
									<div className='fr-col-12'>
										<Radio
											disabled={this.state.groupingIsValidate ?? false}
											isHorizontal={false}
											isSmall={false}
											items={[
												{
													DisplayName: 'Connu',
													Value: '1',
													Checked:
														!this.state.profile?.securityDetails?.familyMembers?.parentOne?.isUnknown
												},
												{
													DisplayName: 'Inconnu',
													Value: '0',
													Checked:
														this.state.profile?.securityDetails?.familyMembers?.parentOne?.isUnknown
												}
											]}
											name='parent-1-known'
											title='Père'
											onChange={(e) => {
												this.setState({
													profile: {
														...this.state.profile,
														securityDetails: {
															...this.state.profile?.securityDetails,
															familyMembers: {
																...this.state.profile?.securityDetails?.familyMembers,
																parentOne: {
																	isUnknown: e.currentTarget.value === '0'
																}
															}
														}
													}
												});
											}}></Radio>
									</div>
								</div>
								<div className='fr-grid-row'>
									<div className='fr-col-12'>
										<Input
											title='Nom'
											type={InputType.Text}
											value={this.state.profile?.securityDetails?.familyMembers?.parentOne?.lastName}
											disabled={
												this.state.profile?.securityDetails?.familyMembers?.parentOne?.isUnknown ||
												(this.state.groupingIsValidate ?? false)
											}
											onChange={(e) => {
												this.setState({
													profile: {
														...this.state.profile,
														securityDetails: {
															...this.state.profile?.securityDetails,
															familyMembers: {
																...this.state.profile?.securityDetails?.familyMembers,
																parentOne: {
																	...this.state.profile?.securityDetails?.familyMembers
																		?.parentOne,
																	lastName: e
																}
															}
														}
													}
												});
											}}></Input>
									</div>
								</div>
								<div className='fr-grid-row'>
									<div className='fr-col-12'>
										<Input
											title='Prénom'
											type={InputType.Text}
											value={
												this.state.profile?.securityDetails?.familyMembers?.parentOne?.firstAndMiddleNames
											}
											disabled={
												this.state.profile?.securityDetails?.familyMembers?.parentOne?.isUnknown ||
												(this.state.groupingIsValidate ?? false)
											}
											onChange={(e) => {
												this.setState({
													profile: {
														...this.state.profile,
														securityDetails: {
															...this.state.profile?.securityDetails,
															familyMembers: {
																...this.state.profile?.securityDetails?.familyMembers,
																parentOne: {
																	...this.state.profile?.securityDetails?.familyMembers
																		?.parentOne,
																	firstAndMiddleNames: e
																}
															}
														}
													}
												});
											}}></Input>
									</div>
								</div>
								<div className='fr-grid-row'>
									<div className='fr-col-12'>
										<Input
											title='Date de naissance'
											type={InputType.Date}
											value={DateService.Convert(
												this.state.profile?.securityDetails?.familyMembers?.parentOne?.dateOfBirth || ''
											)}
											disabled={
												this.state.profile?.securityDetails?.familyMembers?.parentOne?.isUnknown ||
												(this.state.groupingIsValidate ?? false)
											}
											onChange={(e) => {
												this.setState({
													profile: {
														...this.state.profile,
														securityDetails: {
															...this.state.profile?.securityDetails,
															familyMembers: {
																...this.state.profile?.securityDetails?.familyMembers,
																parentOne: {
																	...this.state.profile?.securityDetails?.familyMembers
																		?.parentOne,
																	dateOfBirth: DateService.Convert(e)
																}
															}
														}
													}
												});
											}}></Input>
									</div>
								</div>
								<div className='fr-grid-row'>
									<div className='fr-col-12'>
										<NationSelect
											label='Pays de naissance'
											value={this.state.profile?.securityDetails?.familyMembers?.parentOne?.birthCountryId}
											type={ReferentialType.NATION}
											customOptionLabel={(o: any) => o.shortName}
											disabled={
												this.state.profile?.securityDetails?.familyMembers?.parentOne?.isUnknown ||
												(this.state.groupingIsValidate ?? false)
											}
											onChange={(e) => {
												this.setState({
													showCityFreeFieldsParentOne: e?.name !== 'FRANCE',
													profile: {
														...this.state.profile,
														securityDetails: {
															...this.state.profile?.securityDetails,
															familyMembers: {
																...this.state.profile?.securityDetails?.familyMembers,
																parentOne: {
																	...this.state.profile?.securityDetails?.familyMembers
																		?.parentOne,
																	birthCountryId: e?.id,
																	birthCityId: undefined,
																	birthCity: {
																		...this.state.profile?.personal?.identity?.birthCity,
																		id: undefined,
																		zipCode: e?.name === 'MONACO' ? '98000' : '99999',
																		name: '',
																		inseeCode: ''
																	}
																}
															}
														}
													}
												});
											}}
										/>
									</div>
								</div>
								{!this.state.showCityFreeFieldsParentOne && (
									<>
										<div className='fr-grid-row'>
											<div className='fr-col-12'>
												<ReferentialSelectOptimise
													label='Code postal du lieu de naissance'
													value={
														this.state.profile?.securityDetails?.familyMembers?.parentOne?.birthCity?.id
													}
													type={ReferentialType.ZIPCODE}
													customOptionLabel={(o: any) => o.zipCode}
													disabled={
														this.state.profile?.securityDetails?.familyMembers?.parentOne
															?.isUnknown ||
														(this.state.groupingIsValidate ?? false)
													}
													zipCode={this.state.profile?.securityDetails?.familyMembers?.parentOne?.birthCity?.zipCode}
													onChange={(e) => {
														this.setState({
															profile: {
																...this.state.profile,
																securityDetails: {
																	...this.state.profile?.securityDetails,
																	familyMembers: {
																		...this.state.profile?.securityDetails?.familyMembers,
																		parentOne: {
																			...this.state.profile?.securityDetails?.familyMembers
																				?.parentOne,
																			birthCityId: undefined,
																			birthCity: {
																				...this.state.profile?.securityDetails?.familyMembers?.parentOne?.birthCity,
																				zipCode: e?.zipCode,
																				id: e?.id
																			}
																		}
																	}
																}
															}
														});
													}}
												/>
											</div>
										</div>
										<div className='fr-grid-row'>
											<div className='fr-col-12'>
												<ReferentialSelectOptimise
													label='Commune de naissance'
													value={
														this.state.profile?.securityDetails?.familyMembers?.parentOne?.birthCityId
													}
													type={ReferentialType.CITY}
													zipCode={this.state.profile?.securityDetails?.familyMembers?.parentOne?.birthCity?.zipCode}
													disabled={
														this.state.profile?.securityDetails?.familyMembers?.parentOne
															?.isUnknown ||
														(this.state.groupingIsValidate ?? false)
													}
													name={this.state.profile?.securityDetails?.familyMembers?.parentOne?.birthCity?.name}
													onChange={(e) => {
														this.setState({
															profile: {
																...this.state.profile,
																securityDetails: {
																	...this.state.profile?.securityDetails,
																	familyMembers: {
																		...this.state.profile?.securityDetails?.familyMembers,
																		parentOne: {
																			...this.state.profile?.securityDetails?.familyMembers
																				?.parentOne,
																			birthCityId: e?.id,
																			birthCity: {
																				...this.state.profile?.securityDetails
																					?.familyMembers?.parentOne?.birthCity,
																				zipCode: e?.zipCode,
																				id: e?.id,
																				name:
																					e?.name != 'Sélectionnez'
																						? e?.name
																						: undefined
																			}
																		}
																	}
																}
															}
														});
													}}
												/>
											</div>
										</div>
									</>
								)}
								{this.state.showCityFreeFieldsParentOne && (
									<>
										<div className='fr-col-12 fr-col-md-6'>
											<Input
												title='Code postal du pays de naissance'
												type={InputType.Text}
												disabled={this.state.groupingIsValidate ?? false}
												onChange={(e) =>
													this.setState({
														profile: {
															...this.state.profile,
															securityDetails: {
																...this.state.profile?.securityDetails,
																familyMembers: {
																	...this.state.profile?.securityDetails?.familyMembers,
																	parentOne: {
																		...this.state.profile?.securityDetails?.familyMembers
																			?.parentOne,
																		birthCity: {
																			...this.state.profile?.securityDetails?.familyMembers
																				?.parentOne?.birthCity,
																			zipCode: e
																		}
																	}
																}
															}
														}
													})
												}
												value={
													this.state.profile?.securityDetails?.familyMembers?.parentOne?.birthCity
														?.zipCode
												}></Input>
										</div>
										<div className='fr-col-12 fr-col-md-6'>
											<Input
												title='Commune de naissance'
												type={InputType.Text}
												disabled={this.state.groupingIsValidate ?? false}
												onChange={(e) =>
													this.setState({
														profile: {
															...this.state.profile,
															securityDetails: {
																...this.state.profile?.securityDetails,
																familyMembers: {
																	...this.state.profile?.securityDetails?.familyMembers,
																	parentOne: {
																		...this.state.profile?.securityDetails?.familyMembers
																			?.parentOne,
																		birthCity: {
																			...this.state.profile?.securityDetails?.familyMembers
																				?.parentOne?.birthCity,
																			name: e
																		}
																	}
																}
															}
														}
													})
												}
												value={
													this.state.profile?.securityDetails?.familyMembers?.parentOne?.birthCity?.name
												}></Input>
										</div>
									</>
								)}
								<div className='fr-grid-row'>
									<div className='fr-col-12'>
										<NationSelect
											label='Pays de résidence'
											value={this.state.profile?.securityDetails?.familyMembers?.parentOne?.address?.countryId}
											type={ReferentialType.NATION}
											customOptionLabel={(o: any) => o.shortName}
											disabled={
												this.state.profile?.securityDetails?.familyMembers?.parentOne?.isUnknown ||
												(this.state.groupingIsValidate ?? false)
											}
											onChange={(e) => {
												this.setState({
													showAddressCityFreeFieldsParentOne: e?.name !== 'FRANCE',
													profile: {
														...this.state.profile,
														securityDetails: {
															...this.state.profile?.securityDetails,
															familyMembers: {
																...this.state.profile?.securityDetails?.familyMembers,
																parentOne: {
																	...this.state.profile?.securityDetails?.familyMembers
																		?.parentOne,
																	address: {
																		...this.state.profile?.securityDetails?.familyMembers?.parentOne?.address,
																		countryId: e?.id,
																		cityId: undefined,
																		city: {
																			...this.state.profile?.securityDetails?.familyMembers?.parentOne?.address?.city,
																			id: undefined,
																			zipCode: e?.name === 'MONACO' ? '98000' : '99999',
																			name: '',
																			inseeCode: ''
																		}
																	}
																}
															}
														}
													}
												});
											}}
										/>
									</div>
								</div>
								{!this.state.showAddressCityFreeFieldsParentOne && (
									<>
										<div className='fr-grid-row'>
											<div className='fr-col-12'>
												<ReferentialSelectOptimise
													label='Code postal du lieu de résidence'
													value={
														this.state.profile?.securityDetails?.familyMembers?.parentOne?.address?.city?.id
													}
													type={ReferentialType.ZIPCODE}
													customOptionLabel={(o: any) => o.zipCode}
													disabled={
														this.state.profile?.securityDetails?.familyMembers?.parentOne
															?.isUnknown ||
														(this.state.groupingIsValidate ?? false)
													}
													zipCode={this.state.profile?.securityDetails?.familyMembers?.parentOne?.address?.city?.zipCode}
													onChange={(e) => {
														this.setState({
															profile: {
																...this.state.profile,
																securityDetails: {
																	...this.state.profile?.securityDetails,
																	familyMembers: {
																		...this.state.profile?.securityDetails?.familyMembers,
																		parentOne: {
																			...this.state.profile?.securityDetails?.familyMembers
																				?.parentOne,
																			address: {
																				...this.state.profile?.securityDetails?.familyMembers?.parentOne?.address,
																				cityId: undefined,
																				city: {
																					...this.state.profile?.securityDetails?.familyMembers?.parentOne?.address?.city,
																					zipCode: e?.zipCode,
																					id: e?.id
																				}
																			}
																		}
																	}
																}
															}
														});
													}}
												/>
											</div>
										</div>
										<div className='fr-grid-row'>
											<div className='fr-col-12'>
												<ReferentialSelectOptimise
													label='Commune du lieu de résidence'
													value={
														this.state.profile?.securityDetails?.familyMembers?.parentOne?.address?.cityId
													}
													type={ReferentialType.CITY}
													zipCode={this.state.profile?.securityDetails?.familyMembers?.parentOne?.address?.city?.zipCode}
													disabled={
														this.state.profile?.securityDetails?.familyMembers?.parentOne
															?.isUnknown ||
														(this.state.groupingIsValidate ?? false)
													}
													name={this.state.profile?.securityDetails?.familyMembers?.parentOne?.address?.city?.name}
													onChange={(e) => {
														this.setState({
															profile: {
																...this.state.profile,
																securityDetails: {
																	...this.state.profile?.securityDetails,
																	familyMembers: {
																		...this.state.profile?.securityDetails?.familyMembers,
																		parentOne: {
																			...this.state.profile?.securityDetails?.familyMembers
																				?.parentOne,
																			address: {
																				...this.state.profile?.securityDetails?.familyMembers?.parentOne?.address,
																				cityId:	e?.id,
																				city: {
																					...this.state.profile?.securityDetails
																						?.familyMembers?.parentOne?.address?.city,
																					zipCode: e?.zipCode,
																					id: e?.id,
																					name:
																						e?.name != 'Sélectionnez'
																							? e?.name
																							: undefined
																				}
																			}
																		}
																	}
																}
															}
														});
													}}
												/>
											</div>
										</div>
									</>
								)}
								{this.state.showAddressCityFreeFieldsParentOne && (
									<>
										<div className='fr-col-12 fr-col-md-6'>
											<Input
												title='Code postal du pays de résidence'
												type={InputType.Text}
												disabled={this.state.groupingIsValidate ?? false}
												onChange={(e) =>
													this.setState({
														profile: {
															...this.state.profile,
															securityDetails: {
																...this.state.profile?.securityDetails,
																familyMembers: {
																	...this.state.profile?.securityDetails?.familyMembers,
																	parentOne: {
																		...this.state.profile?.securityDetails?.familyMembers
																			?.parentOne,
																		address: {
																			...this.state.profile?.securityDetails?.familyMembers
																				?.parentOne?.address,
																			city: {
																				...this.state.profile?.securityDetails?.familyMembers
																				?.parentOne?.address?.city,
																				zipCode: e
																			}
																		}
																	}
																}
															}
														}
													})
												}
												value={
													this.state.profile?.securityDetails?.familyMembers?.parentOne?.address?.city
														?.zipCode
												}></Input>
										</div>
										<div className='fr-col-12 fr-col-md-6'>
											<Input
												title='Commune du lieu de résidence'
												type={InputType.Text}
												disabled={this.state.groupingIsValidate ?? false}
												onChange={(e) =>
													this.setState({
														profile: {
															...this.state.profile,
															securityDetails: {
																...this.state.profile?.securityDetails,
																familyMembers: {
																	...this.state.profile?.securityDetails?.familyMembers,
																	parentOne: {
																		...this.state.profile?.securityDetails?.familyMembers
																			?.parentOne,
																		address: {
																			...this.state.profile?.securityDetails?.familyMembers
																				?.parentOne?.address,
																			city: {
																				...this.state.profile?.securityDetails?.familyMembers
																				?.parentOne?.address?.city,
																				name: e
																			}
																		}
																	}
																}
															}
														}
													})
												}
												value={
													this.state.profile?.securityDetails?.familyMembers?.parentOne?.address?.city?.name
												}></Input>
										</div>
									</>
								)}
								<div className='fr-grid-row'>
									<div className='fr-col-12'>
										<Input
											title='Adresse'
											description='Numéro et rue'
											type={InputType.Text}
											value={this.state.profile?.securityDetails?.familyMembers?.parentOne?.address?.street}
											disabled={
												this.state.profile?.securityDetails?.familyMembers?.parentOne?.isUnknown ||
												(this.state.groupingIsValidate ?? false)
											}
											onChange={(e) => {
												this.setState({
													profile: {
														...this.state.profile,
														securityDetails: {
															...this.state.profile?.securityDetails,
															familyMembers: {
																...this.state.profile?.securityDetails?.familyMembers,
																parentOne: {
																	...this.state.profile?.securityDetails?.familyMembers
																		?.parentOne,
																	address: {
																		...this.state.profile?.securityDetails?.familyMembers
																			?.parentOne?.address,
																		street: e
																	}
																}
															}
														}
													}
												});
											}}></Input>
									</div>
								</div>
								<div className='fr-grid-row'>
									<div className='fr-col-12'>
										<Input
											title="Complément d'adresse"
											description='Bâtiment, immeuble, escalier...'
											type={InputType.Text}
											value={
												this.state.profile?.securityDetails?.familyMembers?.parentOne?.address
													?.additionalInformation
											}
											disabled={
												this.state.profile?.securityDetails?.familyMembers?.parentOne?.isUnknown ||
												(this.state.groupingIsValidate ?? false)
											}
											onChange={(e) => {
												this.setState({
													profile: {
														...this.state.profile,
														securityDetails: {
															...this.state.profile?.securityDetails,
															familyMembers: {
																...this.state.profile?.securityDetails?.familyMembers,
																parentOne: {
																	...this.state.profile?.securityDetails?.familyMembers
																		?.parentOne,
																	address: {
																		...this.state.profile?.securityDetails?.familyMembers
																			?.parentOne?.address,
																		additionalInformation: e
																	}
																}
															}
														}
													}
												});
											}}></Input>
									</div>
								</div>
								<div className='fr-grid-row'>
									<div className='fr-col-12'>
										<NationSelect
											label='Nationalité actuelle'
											value={
												this.state.profile?.securityDetails?.familyMembers?.parentOne?.nationalities
													?.currentNationalityId
											}
											type={ReferentialType.NATIONALITY}
											customOptionLabel={(o: any) => {
												return o.formattedNationality;
											}}
											disabled={
												this.state.profile?.securityDetails?.familyMembers?.parentOne?.isUnknown ||
												(this.state.groupingIsValidate ?? false)
											}
											onChange={(e) => {
												this.setState({
													profile: {
														...this.state.profile,
														securityDetails: {
															...this.state.profile?.securityDetails,
															familyMembers: {
																...this.state.profile?.securityDetails?.familyMembers,
																parentOne: {
																	...this.state.profile?.securityDetails?.familyMembers
																		?.parentOne,
																	nationalities: {
																		...this.state.profile?.securityDetails?.familyMembers
																			?.parentOne?.nationalities,
																		currentNationalityId: e?.id
																	}
																}
															}
														}
													}
												});
											}}
										/>
									</div>
								</div>
								<div className='fr-grid-row'>
									<div className='fr-col-12'>
										<NationSelect
											label='Nationalité de naissance'
											value={
												this.state.profile?.securityDetails?.familyMembers?.parentOne?.nationalities
													?.birthNationalityId
											}
											type={ReferentialType.NATIONALITY}
											customOptionLabel={(o: any) => {
												return o.formattedNationality;
											}}
											disabled={
												this.state.profile?.securityDetails?.familyMembers?.parentOne?.isUnknown ||
												(this.state.groupingIsValidate ?? false)
											}
											onChange={(e) => {
												this.setState({
													profile: {
														...this.state.profile,
														securityDetails: {
															...this.state.profile?.securityDetails,
															familyMembers: {
																...this.state.profile?.securityDetails?.familyMembers,
																parentOne: {
																	...this.state.profile?.securityDetails?.familyMembers
																		?.parentOne,
																	nationalities: {
																		...this.state.profile?.securityDetails?.familyMembers
																			?.parentOne?.nationalities,
																		birthNationalityId: e?.id
																	}
																}
															}
														}
													}
												});
											}}
										/>
									</div>
								</div>
								<div
									className='fr-grid-row'
									style={{ marginBottom: '2rem', flexDirection: 'column' }}>
									<div className='fr-col-12'>
										<NationSelect
											label='Autre nationalité'
											value={
												this.state.profile?.securityDetails?.familyMembers?.parentOne?.nationalities
													?.otherNationalityIdOne
											}
											type={ReferentialType.NATIONALITY}
											customOptionLabel={(o: any) => {
												return o.formattedNationality;
											}}
											disabled={
												this.state.profile?.securityDetails?.familyMembers?.parentOne?.isUnknown ||
												(!this.state.toggleParentOneNationalityOne &&
													this.state.profile?.securityDetails?.familyMembers?.parentOne?.nationalities
														?.otherNationalityIdOne === 0) ||
												(this.state.groupingIsValidate ?? false)
											}
											onChange={(e) => {
												this.setState({
													profile: {
														...this.state.profile,
														securityDetails: {
															...this.state.profile?.securityDetails,
															familyMembers: {
																...this.state.profile?.securityDetails?.familyMembers,
																parentOne: {
																	...this.state.profile?.securityDetails?.familyMembers
																		?.parentOne,
																	nationalities: {
																		...this.state.profile?.securityDetails?.familyMembers
																			?.parentOne?.nationalities,
																		otherNationalityIdOne: e?.id
																	}
																}
															}
														}
													}
												});
											}}
										/>
									</div>
									{(this.state.toggleParentOneNationalityTwo ||
										this.state.profile?.securityDetails?.familyMembers?.parentOne?.nationalities
											?.otherNationalityIdTwo !== 0) && (
										<NationSelect
											label='Autre nationalité'
											value={
												this.state.profile?.securityDetails?.familyMembers?.parentOne?.nationalities?.otherNationalityIdTwo?.toString() !==
												'0'
													? this.state.profile?.securityDetails?.familyMembers?.parentOne?.nationalities?.otherNationalityIdTwo?.toString()
													: ''
											}
											disabled={
												this.state.profile?.securityDetails?.familyMembers?.parentOne?.isUnknown ||
												(this.state.groupingIsValidate ?? false)
											}
											type={ReferentialType.NATIONALITY}
											customOptionLabel={(o: any) => {
												return o.formattedNationality;
											}}
											onChange={(e) => {
												this.setState({
													profile: {
														...this.state.profile,
														securityDetails: {
															...this.state.profile?.securityDetails,
															familyMembers: {
																...this.state.profile?.securityDetails?.familyMembers,
																parentOne: {
																	...this.state.profile?.securityDetails?.familyMembers
																		?.parentOne,
																	nationalities: {
																		...this.state.profile?.securityDetails?.familyMembers
																			?.parentOne?.nationalities,
																		otherNationalityIdTwo: e?.id
																	}
																}
															}
														}
													}
												});
											}}></NationSelect>
									)}
									<Button
										disabled={
											this.state.profile?.securityDetails?.familyMembers?.parentOne?.isUnknown ||
											(this.state.profile?.securityDetails?.familyMembers?.parentOne?.nationalities
												?.otherNationalityIdOne != undefined &&
												this.state.profile?.securityDetails?.familyMembers?.parentOne?.nationalities
													?.otherNationalityIdOne != 0 &&
												this.state.profile?.securityDetails?.familyMembers?.parentOne?.nationalities
													?.otherNationalityIdTwo != undefined &&
												this.state.profile?.securityDetails?.familyMembers?.parentOne?.nationalities
													?.otherNationalityIdTwo != 0) ||
											(this.state.toggleParentOneNationalityOne &&
												this.state.toggleParentOneNationalityTwo) ||
											(this.state.groupingIsValidate ?? false)
										}
										size={ButtonSize.Small}
										text='Ajouter une nationalité'
										iconClassName='fr-icon-add-line'
										iconState={ButtonIconState.Left}
										type={ButtonType.Tertiary}
										onClick={() => {
											this.setState({
												toggleParentOneNationalityTwo: this.state.toggleParentOneNationalityOne,
												toggleParentOneNationalityOne: true
											});
										}}></Button>
								</div>
								<div className='fr-grid-row'>
									<div className='fr-col-12'>
										<Input
											title="Année d'acquisition de la nationalité française"
											type={InputType.Text}
											value={
												this.state.profile?.securityDetails?.familyMembers?.parentOne?.nationalities?.obtainingFrenchNationalityYear?.toString() !==
												'0'
													? this.state.profile?.securityDetails?.familyMembers?.parentOne?.nationalities?.obtainingFrenchNationalityYear?.toString()
													: ''
											}
											disabled={
												this.state.profile?.securityDetails?.familyMembers?.parentOne?.isUnknown ||
												(this.state.groupingIsValidate ?? false)
											}
											onChange={(e) => {
												this.setState({
													profile: {
														...this.state.profile,
														securityDetails: {
															...this.state.profile?.securityDetails,
															familyMembers: {
																...this.state.profile?.securityDetails?.familyMembers,
																parentOne: {
																	...this.state.profile?.securityDetails?.familyMembers
																		?.parentOne,
																	nationalities: {
																		...this.state.profile?.securityDetails?.familyMembers
																			?.parentOne?.nationalities,
																		obtainingFrenchNationalityYear: +e
																	}
																}
															}
														}
													}
												});
											}}></Input>
									</div>
								</div>
								<div className='fr-grid-row'>
									<div className='fr-col-12'>
										<Input
											title="Année d'arrivée en France"
											type={InputType.Text}
											value={
												this.state.profile?.securityDetails?.familyMembers?.parentOne?.nationalities?.arrivalInFrance?.toString() !==
												'0'
													? this.state.profile?.securityDetails?.familyMembers?.parentOne?.nationalities?.arrivalInFrance?.toString()
													: ''
											}
											disabled={
												this.state.profile?.securityDetails?.familyMembers?.parentOne?.isUnknown ||
												(this.state.groupingIsValidate ?? false)
											}
											onChange={(e) => {
												this.setState({
													profile: {
														...this.state.profile,
														securityDetails: {
															...this.state.profile?.securityDetails,
															familyMembers: {
																...this.state.profile?.securityDetails?.familyMembers,
																parentOne: {
																	...this.state.profile?.securityDetails?.familyMembers
																		?.parentOne,
																	nationalities: {
																		...this.state.profile?.securityDetails?.familyMembers
																			?.parentOne?.nationalities,
																		arrivalInFrance: +e
																	}
																}
															}
														}
													}
												});
											}}></Input>
									</div>
								</div>
								<div className='fr-grid-row'>
									<div className='fr-col-12'>
										<Input
											title='Date de décès'
											type={InputType.Date}
											value={DateService.Convert(
												this.state.profile?.securityDetails?.familyMembers?.parentOne?.dateOfDeath || ''
											)}
											disabled={
												this.state.profile?.securityDetails?.familyMembers?.parentOne?.isUnknown ||
												(this.state.groupingIsValidate ?? false)
											}
											onChange={(e) => {
												this.setState({
													profile: {
														...this.state.profile,
														securityDetails: {
															...this.state.profile?.securityDetails,
															familyMembers: {
																...this.state.profile?.securityDetails?.familyMembers,
																parentOne: {
																	...this.state.profile?.securityDetails?.familyMembers
																		?.parentOne,
																	dateOfDeath: DateService.Convert(e)
																}
															}
														}
													}
												});
											}}></Input>
									</div>
								</div>
								<div className='fr-grid-row'>
									<div className='fr-col-12'>
										<Input
											title='Profession'
											type={InputType.Text}
											value={this.state.profile?.securityDetails?.familyMembers?.parentOne?.jobTitle}
											disabled={
												this.state.profile?.securityDetails?.familyMembers?.parentOne?.isUnknown ||
												(this.state.groupingIsValidate ?? false)
											}
											onChange={(e) => {
												this.setState({
													profile: {
														...this.state.profile,
														securityDetails: {
															...this.state.profile?.securityDetails,
															familyMembers: {
																...this.state.profile?.securityDetails?.familyMembers,
																parentOne: {
																	...this.state.profile?.securityDetails?.familyMembers?.parentOne,
																	jobTitle: e
																}
															}
														}
													}
												});
											}}></Input>
									</div>
								</div>
								<MobileView>
									{!this.state.groupingIsValidate && (
										<div className='fr-grid-row fr-grid-row--center'>
											<div className='fr-col-12'>
												<Button
													className='mobile-button'
													disabled={false}
													onClick={() => {
														this.updateProfile(() => {
															this.displaySuccess();
															let items = [...this.state.tabItems];
															items.forEach((x) => (x.selected = x.id === 'parent-2'));
															this.setState({ tabItems: items });
														});
													}}
													text="Passer à l'onglet suivant"
													type={ButtonType.Primary}
													iconClassName=''
													size={ButtonSize.Medium}
													modalId=''></Button>
											</div>
										</div>
									)}
								</MobileView>
								<BrowserView>
									{!this.state.groupingIsValidate && (
										<div className='fr-grid-row fr-grid-row--right'>
											<div className='fr-col-12 fr-align-right'>
												<Button
													disabled={false}
													onClick={() => {
														this.updateProfile(() => {
															this.displaySuccess();
															let items = [...this.state.tabItems];
															items.forEach((x) => (x.selected = x.id === 'parent-2'));
															this.setState({ tabItems: items });
														});
													}}
													text="Passer à l'onglet suivant"
													type={ButtonType.Primary}
													iconClassName=''
													size={ButtonSize.Medium}
													modalId=''></Button>
											</div>
										</div>
									)}
								</BrowserView>
							</TabItem>
							<TabItem
								id='parent-2'
								tabIndex={1}
								selected={false}
								mobileOnly={false}>
								<div className='fr-grid-row'>
									<div className='fr-col-12'>
										<Radio
											disabled={this.state.groupingIsValidate ?? false}
											isHorizontal={false}
											isSmall={false}
											items={[
												{
													DisplayName: 'Connu',
													Value: '1',
													Checked:
														!this.state.profile?.securityDetails?.familyMembers?.parentTwo?.isUnknown
												},
												{
													DisplayName: 'Inconnu',
													Value: '0',
													Checked:
														this.state.profile?.securityDetails?.familyMembers?.parentTwo?.isUnknown
												}
											]}
											name='parent-2-known'
											title='Père'
											onChange={(e) => {
												this.setState({
													profile: {
														...this.state.profile,
														securityDetails: {
															...this.state.profile?.securityDetails,
															familyMembers: {
																...this.state.profile?.securityDetails?.familyMembers,
																parentTwo: {
																	isUnknown: e.currentTarget.value === '0'
																}
															}
														}
													}
												});
											}}></Radio>
									</div>
								</div>
								<div className='fr-grid-row'>
									<div className='fr-col-12'>
										<Input
											title='Nom'
											type={InputType.Text}
											value={this.state.profile?.securityDetails?.familyMembers?.parentTwo?.lastName}
											disabled={
												this.state.profile?.securityDetails?.familyMembers?.parentTwo?.isUnknown ||
												(this.state.groupingIsValidate ?? false)
											}
											onChange={(e) => {
												this.setState({
													profile: {
														...this.state.profile,
														securityDetails: {
															...this.state.profile?.securityDetails,
															familyMembers: {
																...this.state.profile?.securityDetails?.familyMembers,
																parentTwo: {
																	...this.state.profile?.securityDetails?.familyMembers
																		?.parentTwo,
																	lastName: e
																}
															}
														}
													}
												});
											}}></Input>
									</div>
								</div>
								<div className='fr-grid-row'>
									<div className='fr-col-12'>
										<Input
											title='Prénom'
											type={InputType.Text}
											value={
												this.state.profile?.securityDetails?.familyMembers?.parentTwo?.firstAndMiddleNames
											}
											disabled={
												this.state.profile?.securityDetails?.familyMembers?.parentTwo?.isUnknown ||
												(this.state.groupingIsValidate ?? false)
											}
											onChange={(e) => {
												this.setState({
													profile: {
														...this.state.profile,
														securityDetails: {
															...this.state.profile?.securityDetails,
															familyMembers: {
																...this.state.profile?.securityDetails?.familyMembers,
																parentTwo: {
																	...this.state.profile?.securityDetails?.familyMembers
																		?.parentTwo,
																	firstAndMiddleNames: e
																}
															}
														}
													}
												});
											}}></Input>
									</div>
								</div>
								<div className='fr-grid-row'>
									<div className='fr-col-12'>
										<Input
											title='Date de naissance'
											type={InputType.Date}
											value={DateService.Convert(
												this.state.profile?.securityDetails?.familyMembers?.parentTwo?.dateOfBirth || ''
											)}
											disabled={
												this.state.profile?.securityDetails?.familyMembers?.parentTwo?.isUnknown ||
												(this.state.groupingIsValidate ?? false)
											}
											onChange={(e) => {
												this.setState({
													profile: {
														...this.state.profile,
														securityDetails: {
															...this.state.profile?.securityDetails,
															familyMembers: {
																...this.state.profile?.securityDetails?.familyMembers,
																parentTwo: {
																	...this.state.profile?.securityDetails?.familyMembers
																		?.parentTwo,
																	dateOfBirth: DateService.Convert(e)
																}
															}
														}
													}
												});
											}}></Input>
									</div>
								</div>
								<div className='fr-grid-row'>
									<div className='fr-col-12'>
										<NationSelect
											label='Pays de naissance'
											value={this.state.profile?.securityDetails?.familyMembers?.parentTwo?.birthCountryId}
											type={ReferentialType.NATION}
											customOptionLabel={(o: any) => o.shortName}
											disabled={
												this.state.profile?.securityDetails?.familyMembers?.parentTwo?.isUnknown ||
												(this.state.groupingIsValidate ?? false)
											}
											onChange={(e) => {
												this.setState({
													showCityFreeFieldsParentTwo: e?.name !== 'FRANCE',
													profile: {
														...this.state.profile,
														securityDetails: {
															...this.state.profile?.securityDetails,
															familyMembers: {
																...this.state.profile?.securityDetails?.familyMembers,
																parentTwo: {
																	...this.state.profile?.securityDetails?.familyMembers
																		?.parentTwo,
																	birthCountryId: e?.id,
																	birthCityId: undefined,
																	birthCity: {
																		...this.state.profile?.personal?.identity?.birthCity,
																		zipCode: e?.name === 'MONACO' ? '98000' : '99999',
																		name: '',
																		inseeCode: ''
																	}
																}
															}
														}
													}
												});
											}}
										/>
									</div>
								</div>
								{!this.state.showCityFreeFieldsParentTwo && (
									<>
										<div className='fr-grid-row'>
											<div className='fr-col-12'>
												<ReferentialSelectOptimise
													label='Code postal du lieu de naissance'
													value={
														this.state.profile?.securityDetails?.familyMembers?.parentTwo?.birthCity?.id
													}
													zipCode={this.state.profile?.securityDetails?.familyMembers?.parentTwo?.birthCity?.zipCode}
													type={ReferentialType.ZIPCODE}
													customOptionLabel={(o: any) => o.zipCode}
													disabled={
														this.state.profile?.securityDetails?.familyMembers?.parentTwo
															?.isUnknown ||
														(this.state.groupingIsValidate ?? false)
													}
													onChange={(e) => {
														this.setState({
															profile: {
																...this.state.profile,
																securityDetails: {
																	...this.state.profile?.securityDetails,
																	familyMembers: {
																		...this.state.profile?.securityDetails?.familyMembers,
																		parentTwo: {
																			...this.state.profile?.securityDetails?.familyMembers
																				?.parentTwo,
																			birthCityId: undefined,
																			birthCity: {
																				...this.state.profile?.securityDetails?.familyMembers
																				?.parentTwo?.birthCity,
																				id: e?.id,
																				zipCode: e?.zipCode
																			}
																		}
																	}
																}
															}
														});
													}}
												/>
											</div>
										</div>
										<div className='fr-grid-row'>
											<div className='fr-col-12'>
												<ReferentialSelectOptimise
													label='Commune de naissance'
													value={
														this.state.profile?.securityDetails?.familyMembers?.parentTwo?.birthCityId
													}
													type={ReferentialType.CITY}
													zipCode={this.state.profile?.securityDetails?.familyMembers?.parentTwo?.birthCity?.zipCode}
													name={this.state.profile?.securityDetails?.familyMembers?.parentTwo?.birthCity?.name}
													disabled={
														this.state.profile?.securityDetails?.familyMembers?.parentTwo
															?.isUnknown ||
														(this.state.groupingIsValidate ?? false)
													}
													onChange={(e) => {
														this.setState({
															profile: {
																...this.state.profile,
																securityDetails: {
																	...this.state.profile?.securityDetails,
																	familyMembers: {
																		...this.state.profile?.securityDetails?.familyMembers,
																		parentTwo: {
																			...this.state.profile?.securityDetails?.familyMembers
																				?.parentTwo,
																			birthCityId: e?.id,
																			birthCity: {
																				...this.state.profile?.securityDetails
																					?.familyMembers?.parentTwo?.birthCity,
																				zipCode: e?.zipCode,
																				id: e?.id,
																				name:
																					e?.name != 'Sélectionnez'
																						? e?.name
																						: undefined
																			}
																		}
																	}
																}
															}
														});
													}}
												/>
											</div>
										</div>
									</>
								)}
								{this.state.showCityFreeFieldsParentTwo && (
									<>
										<div className='fr-col-12 fr-col-md-6'>
											<Input
												title='Code postal du pays de naissance'
												disabled={this.state.groupingIsValidate ?? false}
												type={InputType.Text}
												onChange={(e) =>
													this.setState({
														profile: {
															...this.state.profile,
															securityDetails: {
																...this.state.profile?.securityDetails,
																familyMembers: {
																	...this.state.profile?.securityDetails?.familyMembers,
																	parentTwo: {
																		...this.state.profile?.securityDetails?.familyMembers
																			?.parentTwo,
																		birthCity: {
																			...this.state.profile?.securityDetails?.familyMembers
																				?.parentTwo?.birthCity,
																			zipCode: e
																		}
																	}
																}
															}
														}
													})
												}
												value={
													this.state.profile?.securityDetails?.familyMembers?.parentTwo?.birthCity
														?.zipCode
												}></Input>
										</div>
										<div className='fr-col-12 fr-col-md-6'>
											<Input
												title='Commune de naissance'
												disabled={this.state.groupingIsValidate ?? false}
												type={InputType.Text}
												onChange={(e) =>
													this.setState({
														profile: {
															...this.state.profile,
															securityDetails: {
																...this.state.profile?.securityDetails,
																familyMembers: {
																	...this.state.profile?.securityDetails?.familyMembers,
																	parentTwo: {
																		...this.state.profile?.securityDetails?.familyMembers
																			?.parentTwo,
																		birthCity: {
																			...this.state.profile?.securityDetails?.familyMembers
																				?.parentTwo?.birthCity,
																			name: e
																		}
																	}
																}
															}
														}
													})
												}
												value={
													this.state.profile?.securityDetails?.familyMembers?.parentTwo?.birthCity?.name
												}></Input>
										</div>
									</>
								)}
								<div className='fr-grid-row'>
									<div className='fr-col-12'>
										<NationSelect
											label='Pays de résidence'
											value={this.state.profile?.securityDetails?.familyMembers?.parentTwo?.address?.countryId}
											type={ReferentialType.NATION}
											customOptionLabel={(o: any) => o.shortName}
											disabled={
												this.state.profile?.securityDetails?.familyMembers?.parentTwo?.isUnknown ||
												(this.state.groupingIsValidate ?? false)
											}
											onChange={(e) => {
												this.setState({
													showAddressCityFreeFieldsParentTwo: e?.name !== 'FRANCE',
													profile: {
														...this.state.profile,
														securityDetails: {
															...this.state.profile?.securityDetails,
															familyMembers: {
																...this.state.profile?.securityDetails?.familyMembers,
																parentTwo: {
																	...this.state.profile?.securityDetails?.familyMembers
																		?.parentTwo,
																	address: {
																		...this.state.profile?.securityDetails?.familyMembers?.parentTwo?.address,
																		countryId: e?.id,
																		cityId: undefined,
																		city: {
																			...this.state.profile?.securityDetails?.familyMembers?.parentTwo?.address?.city,
																			id: undefined,
																			zipCode: e?.name === 'MONACO' ? '98000' : '99999',
																			name: '',
																			inseeCode: ''
																		}
																	}
																}
															}
														}
													}
												});
											}}
										/>
									</div>
								</div>
								{!this.state.showAddressCityFreeFieldsParentTwo && (
									<>
										<div className='fr-grid-row'>
											<div className='fr-col-12'>
												<ReferentialSelectOptimise
													label='Code postal du lieu de résidence'
													value={
														this.state.profile?.securityDetails?.familyMembers?.parentTwo?.address?.city?.id
													}
													type={ReferentialType.ZIPCODE}
													customOptionLabel={(o: any) => o.zipCode}
													disabled={
														this.state.profile?.securityDetails?.familyMembers?.parentTwo
															?.isUnknown ||
														(this.state.groupingIsValidate ?? false)
													}
													zipCode={this.state.profile?.securityDetails?.familyMembers?.parentTwo?.address?.city?.zipCode}
													onChange={(e) => {
														this.setState({
															profile: {
																...this.state.profile,
																securityDetails: {
																	...this.state.profile?.securityDetails,
																	familyMembers: {
																		...this.state.profile?.securityDetails?.familyMembers,
																		parentTwo: {
																			...this.state.profile?.securityDetails?.familyMembers
																				?.parentTwo,
																			address: {
																				...this.state.profile?.securityDetails?.familyMembers?.parentTwo?.address,
																				cityId: undefined,
																				city: {
																					...this.state.profile?.securityDetails?.familyMembers?.parentTwo?.address?.city,
																					zipCode: e?.zipCode,
																					id: e?.id
																				}
																			}
																		}
																	}
																}
															}
														});
													}}
												/>
											</div>
										</div>
										<div className='fr-grid-row'>
											<div className='fr-col-12'>
												<ReferentialSelectOptimise
													label='Commune du lieu de résidence'
													value={
														this.state.profile?.securityDetails?.familyMembers?.parentTwo?.address?.cityId
													}
													type={ReferentialType.CITY}
													zipCode={this.state.profile?.securityDetails?.familyMembers?.parentTwo?.address?.city?.zipCode}
													disabled={
														this.state.profile?.securityDetails?.familyMembers?.parentTwo
															?.isUnknown ||
														(this.state.groupingIsValidate ?? false)
													}
													name={this.state.profile?.securityDetails?.familyMembers?.parentTwo?.address?.city?.name}
													onChange={(e) => {
														this.setState({
															profile: {
																...this.state.profile,
																securityDetails: {
																	...this.state.profile?.securityDetails,
																	familyMembers: {
																		...this.state.profile?.securityDetails?.familyMembers,
																		parentTwo: {
																			...this.state.profile?.securityDetails?.familyMembers
																				?.parentTwo,
																			address: {
																				...this.state.profile?.securityDetails?.familyMembers?.parentTwo?.address,
																				cityId:	e?.id,
																				city: {
																					...this.state.profile?.securityDetails
																						?.familyMembers?.parentTwo?.address?.city,
																					zipCode: e?.zipCode,
																					id: e?.id,
																					name:
																						e?.name != 'Sélectionnez'
																							? e?.name
																							: undefined
																				}
																			}
																		}
																	}
																}
															}
														});
													}}
												/>
											</div>
										</div>
									</>
								)}
								{this.state.showAddressCityFreeFieldsParentTwo && (
									<>
										<div className='fr-col-12 fr-col-md-6'>
											<Input
												title='Code postal du pays de résidence'
												type={InputType.Text}
												disabled={this.state.groupingIsValidate ?? false}
												onChange={(e) =>
													this.setState({
														profile: {
															...this.state.profile,
															securityDetails: {
																...this.state.profile?.securityDetails,
																familyMembers: {
																	...this.state.profile?.securityDetails?.familyMembers,
																	parentTwo: {
																		...this.state.profile?.securityDetails?.familyMembers
																			?.parentTwo,
																		address: {
																			...this.state.profile?.securityDetails?.familyMembers
																				?.parentTwo?.address,
																			city: {
																				...this.state.profile?.securityDetails?.familyMembers
																				?.parentTwo?.address?.city,
																				zipCode: e
																			}
																		}
																	}
																}
															}
														}
													})
												}
												value={
													this.state.profile?.securityDetails?.familyMembers?.parentTwo?.address?.city
														?.zipCode
												}></Input>
										</div>
										<div className='fr-col-12 fr-col-md-6'>
											<Input
												title='Commune du lieu de résidence'
												type={InputType.Text}
												disabled={this.state.groupingIsValidate ?? false}
												onChange={(e) =>
													this.setState({
														profile: {
															...this.state.profile,
															securityDetails: {
																...this.state.profile?.securityDetails,
																familyMembers: {
																	...this.state.profile?.securityDetails?.familyMembers,
																	parentTwo: {
																		...this.state.profile?.securityDetails?.familyMembers
																			?.parentTwo,
																		address: {
																			...this.state.profile?.securityDetails?.familyMembers
																				?.parentTwo?.address,
																			city: {
																				...this.state.profile?.securityDetails?.familyMembers
																				?.parentTwo?.address?.city,
																				name: e
																			}
																		}
																	}
																}
															}
														}
													})
												}
												value={
													this.state.profile?.securityDetails?.familyMembers?.parentTwo?.address?.city?.name
												}></Input>
										</div>
									</>
								)}
								<div className='fr-grid-row'>
									<div className='fr-col-12'>
										<Input
											title='Adresse'
											description='Numéro et rue'
											type={InputType.Text}
											value={this.state.profile?.securityDetails?.familyMembers?.parentTwo?.address?.street}
											disabled={
												this.state.profile?.securityDetails?.familyMembers?.parentTwo?.isUnknown ||
												(this.state.groupingIsValidate ?? false)
											}
											onChange={(e) => {
												this.setState({
													profile: {
														...this.state.profile,
														securityDetails: {
															...this.state.profile?.securityDetails,
															familyMembers: {
																...this.state.profile?.securityDetails?.familyMembers,
																parentTwo: {
																	...this.state.profile?.securityDetails?.familyMembers
																		?.parentTwo,
																	address: {
																		...this.state.profile?.securityDetails?.familyMembers
																			?.parentTwo?.address,
																		street: e
																	}
																}
															}
														}
													}
												});
											}}></Input>
									</div>
								</div>
								<div className='fr-grid-row'>
									<div className='fr-col-12'>
										<Input
											title="Complément d'adresse"
											description='Bâtiment, immeuble, escalier...'
											type={InputType.Text}
											value={
												this.state.profile?.securityDetails?.familyMembers?.parentTwo?.address
													?.additionalInformation
											}
											disabled={
												this.state.profile?.securityDetails?.familyMembers?.parentTwo?.isUnknown ||
												(this.state.groupingIsValidate ?? false)
											}
											onChange={(e) => {
												this.setState({
													profile: {
														...this.state.profile,
														securityDetails: {
															...this.state.profile?.securityDetails,
															familyMembers: {
																...this.state.profile?.securityDetails?.familyMembers,
																parentTwo: {
																	...this.state.profile?.securityDetails?.familyMembers
																		?.parentTwo,
																	address: {
																		...this.state.profile?.securityDetails?.familyMembers
																			?.parentTwo?.address,
																		additionalInformation: e
																	}
																}
															}
														}
													}
												});
											}}></Input>
									</div>
								</div>
								<div className='fr-grid-row'>
									<div className='fr-col-12'>
										<NationSelect
											label='Nationalité actuelle'
											value={
												this.state.profile?.securityDetails?.familyMembers?.parentTwo?.nationalities
													?.currentNationalityId
											}
											type={ReferentialType.NATIONALITY}
											customOptionLabel={(o: any) => {
												return o.formattedNationality;
											}}
											disabled={
												this.state.profile?.securityDetails?.familyMembers?.parentTwo?.isUnknown ||
												(this.state.groupingIsValidate ?? false)
											}
											onChange={(e) => {
												this.setState({
													profile: {
														...this.state.profile,
														securityDetails: {
															...this.state.profile?.securityDetails,
															familyMembers: {
																...this.state.profile?.securityDetails?.familyMembers,
																parentTwo: {
																	...this.state.profile?.securityDetails?.familyMembers
																		?.parentTwo,
																	nationalities: {
																		...this.state.profile?.securityDetails?.familyMembers
																			?.parentTwo?.nationalities,
																		currentNationalityId: e?.id
																	}
																}
															}
														}
													}
												});
											}}
										/>
									</div>
								</div>
								<div className='fr-grid-row'>
									<div className='fr-col-12'>
										<NationSelect
											label='Nationalité de naissance'
											value={
												this.state.profile?.securityDetails?.familyMembers?.parentTwo?.nationalities
													?.birthNationalityId
											}
											type={ReferentialType.NATIONALITY}
											customOptionLabel={(o: any) => {
												return o.formattedNationality;
											}}
											disabled={
												this.state.profile?.securityDetails?.familyMembers?.parentTwo?.isUnknown ||
												(this.state.groupingIsValidate ?? false)
											}
											onChange={(e) => {
												this.setState({
													profile: {
														...this.state.profile,
														securityDetails: {
															...this.state.profile?.securityDetails,
															familyMembers: {
																...this.state.profile?.securityDetails?.familyMembers,
																parentTwo: {
																	...this.state.profile?.securityDetails?.familyMembers
																		?.parentTwo,
																	nationalities: {
																		...this.state.profile?.securityDetails?.familyMembers
																			?.parentTwo?.nationalities,
																		birthNationalityId: e?.id
																	}
																}
															}
														}
													}
												});
											}}
										/>
									</div>
								</div>
								<div
									className='fr-grid-row'
									style={{ marginBottom: '2rem', flexDirection: 'column' }}>
									<div className='fr-col-12'>
										<NationSelect
											label='Autre nationalité'
											value={
												this.state.profile?.securityDetails?.familyMembers?.parentTwo?.nationalities
													?.otherNationalityIdOne
											}
											type={ReferentialType.NATIONALITY}
											customOptionLabel={(o: any) => {
												return o.formattedNationality;
											}}
											disabled={
												this.state.profile?.securityDetails?.familyMembers?.parentTwo?.isUnknown ||
												(!this.state.toggleParentTwoNationalityOne &&
													this.state.profile?.securityDetails?.familyMembers?.parentTwo?.nationalities
														?.otherNationalityIdOne === 0) ||
												(this.state.groupingIsValidate ?? false)
											}
											onChange={(e) => {
												this.setState({
													profile: {
														...this.state.profile,
														securityDetails: {
															...this.state.profile?.securityDetails,
															familyMembers: {
																...this.state.profile?.securityDetails?.familyMembers,
																parentTwo: {
																	...this.state.profile?.securityDetails?.familyMembers
																		?.parentTwo,
																	nationalities: {
																		...this.state.profile?.securityDetails?.familyMembers
																			?.parentTwo?.nationalities,
																		otherNationalityIdOne: e?.id
																	}
																}
															}
														}
													}
												});
											}}
										/>
									</div>
									{(this.state.toggleParentTwoNationalityTwo ||
										this.state.profile?.securityDetails?.familyMembers?.parentTwo?.nationalities
											?.otherNationalityIdTwo !== 0) && (
										<NationSelect
											label='Autre nationalité'
											value={
												this.state.profile?.securityDetails?.familyMembers?.parentTwo?.nationalities?.otherNationalityIdTwo?.toString() !==
												'0'
													? this.state.profile?.securityDetails?.familyMembers?.parentTwo?.nationalities?.otherNationalityIdTwo?.toString()
													: ''
											}
											type={ReferentialType.NATIONALITY}
											disabled={
												this.state.profile?.securityDetails?.familyMembers?.parentTwo?.isUnknown ||
												(this.state.groupingIsValidate ?? false)
											}
											customOptionLabel={(o: any) => {
												return o.formattedNationality;
											}}
											onChange={(e) => {
												this.setState({
													profile: {
														...this.state.profile,
														securityDetails: {
															...this.state.profile?.securityDetails,
															familyMembers: {
																...this.state.profile?.securityDetails?.familyMembers,
																parentTwo: {
																	...this.state.profile?.securityDetails?.familyMembers
																		?.parentTwo,
																	nationalities: {
																		...this.state.profile?.securityDetails?.familyMembers
																			?.parentTwo?.nationalities,
																		otherNationalityIdTwo: e?.id
																	}
																}
															}
														}
													}
												});
											}}></NationSelect>
									)}
									<Button
										disabled={
											this.state.profile?.securityDetails?.familyMembers?.parentTwo?.isUnknown ||
											(this.state.profile?.securityDetails?.familyMembers?.parentTwo?.nationalities
												?.otherNationalityIdOne != undefined &&
												this.state.profile?.securityDetails?.familyMembers?.parentTwo?.nationalities
													?.otherNationalityIdOne != 0 &&
												this.state.profile?.securityDetails?.familyMembers?.parentTwo?.nationalities
													?.otherNationalityIdTwo != undefined &&
												this.state.profile?.securityDetails?.familyMembers?.parentTwo?.nationalities
													?.otherNationalityIdTwo != 0) ||
											(this.state.toggleParentTwoNationalityOne &&
												this.state.toggleParentTwoNationalityTwo) ||
											(this.state.groupingIsValidate ?? false)
										}
										size={ButtonSize.Small}
										text='Ajouter une nationalité'
										iconClassName='fr-icon-add-line'
										iconState={ButtonIconState.Left}
										type={ButtonType.Tertiary}
										onClick={() => {
											this.setState({
												toggleParentTwoNationalityTwo: this.state.toggleParentTwoNationalityOne,
												toggleParentTwoNationalityOne: true
											});
										}}></Button>
								</div>
								<div className='fr-grid-row'>
									<div className='fr-col-12'>
										<Input
											title="Année d'acquisition de la nationalité française"
											type={InputType.Text}
											value={
												this.state.profile?.securityDetails?.familyMembers?.parentTwo?.nationalities?.obtainingFrenchNationalityYear?.toString() !==
												'0'
													? this.state.profile?.securityDetails?.familyMembers?.parentTwo?.nationalities?.obtainingFrenchNationalityYear?.toString()
													: ''
											}
											disabled={
												this.state.profile?.securityDetails?.familyMembers?.parentTwo?.isUnknown ||
												(this.state.groupingIsValidate ?? false)
											}
											onChange={(e) => {
												this.setState({
													profile: {
														...this.state.profile,
														securityDetails: {
															...this.state.profile?.securityDetails,
															familyMembers: {
																...this.state.profile?.securityDetails?.familyMembers,
																parentTwo: {
																	...this.state.profile?.securityDetails?.familyMembers
																		?.parentTwo,
																	nationalities: {
																		...this.state.profile?.securityDetails?.familyMembers
																			?.parentTwo?.nationalities,
																		obtainingFrenchNationalityYear: +e
																	}
																}
															}
														}
													}
												});
											}}></Input>
									</div>
								</div>
								<div className='fr-grid-row'>
									<div className='fr-col-12'>
										<Input
											title="Année d'arrivée en France"
											type={InputType.Text}
											value={
												this.state.profile?.securityDetails?.familyMembers?.parentTwo?.nationalities?.arrivalInFrance?.toString() !==
												'0'
													? this.state.profile?.securityDetails?.familyMembers?.parentTwo?.nationalities?.arrivalInFrance?.toString()
													: ''
											}
											disabled={
												this.state.profile?.securityDetails?.familyMembers?.parentTwo?.isUnknown ||
												(this.state.groupingIsValidate ?? false)
											}
											onChange={(e) => {
												this.setState({
													profile: {
														...this.state.profile,
														securityDetails: {
															...this.state.profile?.securityDetails,
															familyMembers: {
																...this.state.profile?.securityDetails?.familyMembers,
																parentTwo: {
																	...this.state.profile?.securityDetails?.familyMembers
																		?.parentTwo,
																	nationalities: {
																		...this.state.profile?.securityDetails?.familyMembers
																			?.parentTwo?.nationalities,
																		arrivalInFrance: +e
																	}
																}
															}
														}
													}
												});
											}}></Input>
									</div>
								</div>
								<div className='fr-grid-row'>
									<div className='fr-col-12'>
										<Input
											title='Date de décès'
											type={InputType.Date}
											value={DateService.Convert(
												this.state.profile?.securityDetails?.familyMembers?.parentTwo?.dateOfDeath || ''
											)}
											disabled={
												this.state.profile?.securityDetails?.familyMembers?.parentTwo?.isUnknown ||
												(this.state.groupingIsValidate ?? false)
											}
											onChange={(e) => {
												this.setState({
													profile: {
														...this.state.profile,
														securityDetails: {
															...this.state.profile?.securityDetails,
															familyMembers: {
																...this.state.profile?.securityDetails?.familyMembers,
																parentTwo: {
																	...this.state.profile?.securityDetails?.familyMembers
																		?.parentTwo,
																	dateOfDeath: DateService.Convert(e)
																}
															}
														}
													}
												});
											}}></Input>
									</div>
								</div>
								<div className='fr-grid-row'>
									<div className='fr-col-12'>
										<Input
											title='Profession'
											type={InputType.Text}
											value={this.state.profile?.securityDetails?.familyMembers?.parentTwo?.jobTitle}
											disabled={
												this.state.profile?.securityDetails?.familyMembers?.parentTwo?.isUnknown ||
												(this.state.groupingIsValidate ?? false)
											}
											onChange={(e) => {
												this.setState({
													profile: {
														...this.state.profile,
														securityDetails: {
															...this.state.profile?.securityDetails,
															familyMembers: {
																...this.state.profile?.securityDetails?.familyMembers,
																parentTwo: {
																	...this.state.profile?.securityDetails?.familyMembers?.parentTwo,
																	jobTitle: e
																}
															}
														}
													}
												});
											}}></Input>
									</div>
								</div>
								<MobileView>
									{!this.state.groupingIsValidate && (
										<div className='fr-grid-row fr-grid-row--center'>
											<div className='fr-col-12'>
												<Button
													className='mobile-button'
													disabled={false}
													onClick={() => {
														this.updateProfile(() => {
															this.displaySuccess();
															let items = [...this.state.tabItems];
															items.forEach((x) => (x.selected = x.id === 'conjoint'));
															this.setState({ tabItems: items });
														});
													}}
													text="Passer à l'onglet suivant"
													type={ButtonType.Primary}
													iconClassName=''
													size={ButtonSize.Medium}
													modalId=''></Button>
											</div>
										</div>
									)}
								</MobileView>
								<BrowserView>
									{!this.state.groupingIsValidate && (
										<div className='fr-grid-row fr-grid-row--right'>
											<div className='fr-col-12 fr-align-right'>
												<Button
													disabled={false}
													onClick={() => {
														this.updateProfile(() => {
															this.displaySuccess();
															let items = [...this.state.tabItems];
															items.forEach((x) => (x.selected = x.id === 'conjoint'));
															this.setState({ tabItems: items });
														});
													}}
													text="Passer à l'onglet suivant"
													type={ButtonType.Primary}
													iconClassName=''
													size={ButtonSize.Medium}
													modalId=''></Button>
											</div>
										</div>
									)}
								</BrowserView>
							</TabItem>
							<TabItem
								id='conjoint'
								tabIndex={2}
								selected={false}
								mobileOnly={false}>
								<div className='fr-grid-row'>
									<div className='fr-col-12'>
										<Checkbox
											title='Conjoint(e)'
											items={[
												{
													Checked:
														this.state.profile?.securityDetails?.familyMembers?.partner?.isUnknown,
													DisplayName: 'Sans Objet',
													Value: '1'
												}
											]}
											disabled={this.state.groupingIsValidate ?? false}
											onChange={(e) => {
												this.setState({
													profile: {
														...this.state.profile,
														securityDetails: {
															...this.state.profile?.securityDetails,
															familyMembers: {
																...this.state.profile?.securityDetails?.familyMembers,
																partner: {
																	isUnknown: e.currentTarget.checked
																}
															}
														}
													}
												});
											}}></Checkbox>
									</div>
								</div>
								<div className='fr-grid-row'>
									<div className='fr-col-12'>
										<Input
											title='Nom'
											type={InputType.Text}
											value={this.state.profile?.securityDetails?.familyMembers?.partner?.lastName}
											disabled={
												this.state.profile?.securityDetails?.familyMembers?.partner?.isUnknown ||
												(this.state.groupingIsValidate ?? false)
											}
											onChange={(e) => {
												this.setState({
													profile: {
														...this.state.profile,
														securityDetails: {
															...this.state.profile?.securityDetails,
															familyMembers: {
																...this.state.profile?.securityDetails?.familyMembers,
																partner: {
																	...this.state.profile?.securityDetails?.familyMembers
																		?.partner,
																	lastName: e
																}
															}
														}
													}
												});
											}}></Input>
									</div>
								</div>
								<div className='fr-grid-row'>
									<div className='fr-col-12'>
										<Input
											title='Prénom'
											type={InputType.Text}
											value={
												this.state.profile?.securityDetails?.familyMembers?.partner?.firstAndMiddleNames
											}
											disabled={
												this.state.profile?.securityDetails?.familyMembers?.partner?.isUnknown ||
												(this.state.groupingIsValidate ?? false)
											}
											onChange={(e) => {
												this.setState({
													profile: {
														...this.state.profile,
														securityDetails: {
															...this.state.profile?.securityDetails,
															familyMembers: {
																...this.state.profile?.securityDetails?.familyMembers,
																partner: {
																	...this.state.profile?.securityDetails?.familyMembers
																		?.partner,
																	firstAndMiddleNames: e
																}
															}
														}
													}
												});
											}}></Input>
									</div>
								</div>
								<div className='fr-grid-row'>
									<div className='fr-col-12'>
										<Input
											title='Date de naissance'
											type={InputType.Date}
											value={DateService.Convert(
												this.state.profile?.securityDetails?.familyMembers?.partner?.dateOfBirth || ''
											)}
											disabled={
												this.state.profile?.securityDetails?.familyMembers?.partner?.isUnknown ||
												(this.state.groupingIsValidate ?? false)
											}
											onChange={(e) => {
												this.setState({
													profile: {
														...this.state.profile,
														securityDetails: {
															...this.state.profile?.securityDetails,
															familyMembers: {
																...this.state.profile?.securityDetails?.familyMembers,
																partner: {
																	...this.state.profile?.securityDetails?.familyMembers
																		?.partner,
																	dateOfBirth: DateService.Convert(e)
																}
															}
														}
													}
												});
											}}></Input>
									</div>
								</div>
								<div className='fr-grid-row'>
									<div className='fr-col-12'>
										<NationSelect
											label='Pays de naissance'
											value={this.state.profile?.securityDetails?.familyMembers?.partner?.birthCountryId}
											type={ReferentialType.NATION}
											customOptionLabel={(o: any) => o.shortName}
											disabled={
												this.state.profile?.securityDetails?.familyMembers?.partner?.isUnknown ||
												(this.state.groupingIsValidate ?? false)
											}
											onChange={(e) => {
												this.setState({
													showCityFreeFieldsPartner: e?.name !== 'FRANCE',
													profile: {
														...this.state.profile,
														securityDetails: {
															...this.state.profile?.securityDetails,
															familyMembers: {
																...this.state.profile?.securityDetails?.familyMembers,
																partner: {
																	...this.state.profile?.securityDetails?.familyMembers
																		?.partner,
																	birthCountryId: e?.id,
																	birthCityId: undefined,
																	birthCity: {
																		...this.state.profile?.personal?.identity?.birthCity,
																		zipCode: e?.name === 'MONACO' ? '98000' : '99999',
																		name: '',
																		inseeCode: ''
																	}
																}
															}
														}
													}
												});
											}}
										/>
									</div>
								</div>
								{!this.state.showCityFreeFieldsPartner && (
									<>
										<div className='fr-grid-row'>
											<div className='fr-col-12'>
												<ReferentialSelectOptimise
													label='Code postal du lieu de naissance'
													value={
														this.state.profile?.securityDetails?.familyMembers?.partner?.birthCity?.id
													}
													zipCode={this.state.profile?.securityDetails?.familyMembers?.partner?.birthCity?.zipCode}
													type={ReferentialType.ZIPCODE}
													customOptionLabel={(o: any) => o.zipCode}
													disabled={
														this.state.profile?.securityDetails?.familyMembers?.partner?.isUnknown ||
														(this.state.groupingIsValidate ?? false)
													}
													onChange={(e) => {
														this.setState({
															profile: {
																...this.state.profile,
																securityDetails: {
																	...this.state.profile?.securityDetails,
																	familyMembers: {
																		...this.state.profile?.securityDetails?.familyMembers,
																		partner: {
																			...this.state.profile?.securityDetails?.familyMembers
																				?.partner,
																			birthCityId: undefined,
																			birthCity: {
																				...this.state.profile?.securityDetails?.familyMembers
																				?.partner?.birthCity,
																				id: e?.id,
																				zipCode: e?.zipCode
																			}
																		}
																	}
																}
															}
														});
													}}
												/>
											</div>
										</div>
										<div className='fr-grid-row'>
											<div className='fr-col-12'>
												<ReferentialSelectOptimise
													label='Commune de naissance'
													value={
														this.state.profile?.securityDetails?.familyMembers?.partner?.birthCityId
													}
													type={ReferentialType.CITY}
													zipCode={this.state.profile?.securityDetails?.familyMembers?.partner?.birthCity?.zipCode}
													name={this.state.profile?.securityDetails?.familyMembers?.partner?.birthCity?.name}
													disabled={
														this.state.profile?.securityDetails?.familyMembers?.partner?.isUnknown ||
														(this.state.groupingIsValidate ?? false)
													}
													onChange={(e) => {
														this.setState({
															profile: {
																...this.state.profile,
																securityDetails: {
																	...this.state.profile?.securityDetails,
																	familyMembers: {
																		...this.state.profile?.securityDetails?.familyMembers,
																		partner: {
																			...this.state.profile?.securityDetails?.familyMembers
																				?.partner,
																			birthCityId: e?.id,
																			birthCity: {
																				...this.state.profile?.securityDetails
																					?.familyMembers?.partner?.birthCity,
																				zipCode: e?.zipCode,
																				id: e?.id,
																				name:
																					e?.name != 'Sélectionnez'
																						? e?.name
																						: undefined
																			}
																		}
																	}
																}
															}
														});
													}}
												/>
											</div>
										</div>
									</>
								)}
								{this.state.showCityFreeFieldsPartner && (
									<>
										<div className='fr-col-12 fr-col-md-6'>
											<Input
												title='Code postal du pays de naissance'
												disabled={
													this.state.profile?.securityDetails?.familyMembers?.partner?.isUnknown ||
													(this.state.groupingIsValidate ?? false)
												}
												type={InputType.Text}
												onChange={(e) =>
													this.setState({
														profile: {
															...this.state.profile,
															securityDetails: {
																...this.state.profile?.securityDetails,
																familyMembers: {
																	...this.state.profile?.securityDetails?.familyMembers,
																	partner: {
																		...this.state.profile?.securityDetails?.familyMembers
																			?.partner,
																		birthCity: {
																			...this.state.profile?.securityDetails?.familyMembers
																				?.partner?.birthCity,
																			zipCode: e
																		}
																	}
																}
															}
														}
													})
												}
												value={
													this.state.profile?.securityDetails?.familyMembers?.partner?.birthCity
														?.zipCode
												}></Input>
										</div>
										<div className='fr-col-12 fr-col-md-6'>
											<Input
												title='Commune de naissance'
												disabled={
													this.state.profile?.securityDetails?.familyMembers?.partner?.isUnknown ||
													(this.state.groupingIsValidate ?? false)
												}
												type={InputType.Text}
												onChange={(e) =>
													this.setState({
														profile: {
															...this.state.profile,
															securityDetails: {
																...this.state.profile?.securityDetails,
																familyMembers: {
																	...this.state.profile?.securityDetails?.familyMembers,
																	partner: {
																		...this.state.profile?.securityDetails?.familyMembers
																			?.partner,
																		birthCity: {
																			...this.state.profile?.securityDetails?.familyMembers
																				?.partner?.birthCity,
																			name: e
																		}
																	}
																}
															}
														}
													})
												}
												value={
													this.state.profile?.securityDetails?.familyMembers?.partner?.birthCity?.name
												}></Input>
										</div>
									</>
								)}
								<div className='fr-grid-row'>
									<div className='fr-col-12'>
										<NationSelect
											label='Pays de résidence'
											value={this.state.profile?.securityDetails?.familyMembers?.partner?.address?.countryId}
											type={ReferentialType.NATION}
											customOptionLabel={(o: any) => o.shortName}
											disabled={
												this.state.profile?.securityDetails?.familyMembers?.partner?.isUnknown ||
												(this.state.groupingIsValidate ?? false)
											}
											onChange={(e) => {
												this.setState({
													showAddressCityFreeFieldsPartner: e?.name !== 'FRANCE',
													profile: {
														...this.state.profile,
														securityDetails: {
															...this.state.profile?.securityDetails,
															familyMembers: {
																...this.state.profile?.securityDetails?.familyMembers,
																partner: {
																	...this.state.profile?.securityDetails?.familyMembers
																		?.partner,
																	address: {
																		...this.state.profile?.securityDetails?.familyMembers?.partner?.address,
																		countryId: e?.id,
																		cityId: undefined,
																		city: {
																			...this.state.profile?.securityDetails?.familyMembers?.partner?.address?.city,
																			id: undefined,
																			zipCode: e?.name === 'MONACO' ? '98000' : '99999',
																			name: '',
																			inseeCode: ''
																		}
																	}
																}
															}
														}
													}
												});
											}}
										/>
									</div>
								</div>
								{!this.state.showAddressCityFreeFieldsPartner && (
									<>
										<div className='fr-grid-row'>
											<div className='fr-col-12'>
												<ReferentialSelectOptimise
													label='Code postal du lieu de résidence'
													value={
														this.state.profile?.securityDetails?.familyMembers?.partner?.address?.city?.id
													}
													type={ReferentialType.ZIPCODE}
													customOptionLabel={(o: any) => o.zipCode}
													disabled={
														this.state.profile?.securityDetails?.familyMembers?.partner
															?.isUnknown ||
														(this.state.groupingIsValidate ?? false)
													}
													zipCode={this.state.profile?.securityDetails?.familyMembers?.partner?.address?.city?.zipCode}
													onChange={(e) => {
														this.setState({
															profile: {
																...this.state.profile,
																securityDetails: {
																	...this.state.profile?.securityDetails,
																	familyMembers: {
																		...this.state.profile?.securityDetails?.familyMembers,
																		partner: {
																			...this.state.profile?.securityDetails?.familyMembers
																				?.partner,
																			address: {
																				...this.state.profile?.securityDetails?.familyMembers?.partner?.address,
																				cityId: undefined,
																				city: {
																					...this.state.profile?.securityDetails?.familyMembers?.partner?.address?.city,
																					zipCode: e?.zipCode,
																					id: e?.id
																				}
																			}
																		}
																	}
																}
															}
														});
													}}
												/>
											</div>
										</div>
										<div className='fr-grid-row'>
											<div className='fr-col-12'>
												<ReferentialSelectOptimise
													label='Commune du lieu de résidence'
													value={
														this.state.profile?.securityDetails?.familyMembers?.partner?.address?.cityId
													}
													type={ReferentialType.CITY}
													zipCode={this.state.profile?.securityDetails?.familyMembers?.partner?.address?.city?.zipCode}
													disabled={
														this.state.profile?.securityDetails?.familyMembers?.partner
															?.isUnknown ||
														(this.state.groupingIsValidate ?? false)
													}
													name={this.state.profile?.securityDetails?.familyMembers?.partner?.address?.city?.name}
													onChange={(e) => {
														this.setState({
															profile: {
																...this.state.profile,
																securityDetails: {
																	...this.state.profile?.securityDetails,
																	familyMembers: {
																		...this.state.profile?.securityDetails?.familyMembers,
																		partner: {
																			...this.state.profile?.securityDetails?.familyMembers
																				?.partner,
																			address: {
																				...this.state.profile?.securityDetails?.familyMembers?.partner?.address,
																				cityId:	e?.id,
																				city: {
																					...this.state.profile?.securityDetails
																						?.familyMembers?.partner?.address?.city,
																					zipCode: e?.zipCode,
																					id: e?.id,
																					name:
																						e?.name != 'Sélectionnez'
																							? e?.name
																							: undefined
																				}
																			}
																		}
																	}
																}
															}
														});
													}}
												/>
											</div>
										</div>
									</>
								)}
								{this.state.showAddressCityFreeFieldsPartner && (
									<>
										<div className='fr-col-12 fr-col-md-6'>
											<Input
												title='Code postal du pays de résidence'
												type={InputType.Text}
												disabled={this.state.groupingIsValidate ?? false}
												onChange={(e) =>
													this.setState({
														profile: {
															...this.state.profile,
															securityDetails: {
																...this.state.profile?.securityDetails,
																familyMembers: {
																	...this.state.profile?.securityDetails?.familyMembers,
																	partner: {
																		...this.state.profile?.securityDetails?.familyMembers
																			?.partner,
																		address: {
																			...this.state.profile?.securityDetails?.familyMembers
																				?.partner?.address,
																			city: {
																				...this.state.profile?.securityDetails?.familyMembers
																				?.partner?.address?.city,
																				zipCode: e
																			}
																		}
																	}
																}
															}
														}
													})
												}
												value={
													this.state.profile?.securityDetails?.familyMembers?.partner?.address?.city
														?.zipCode
												}></Input>
										</div>
										<div className='fr-col-12 fr-col-md-6'>
											<Input
												title='Commune du lieu de résidence'
												type={InputType.Text}
												disabled={this.state.groupingIsValidate ?? false}
												onChange={(e) =>
													this.setState({
														profile: {
															...this.state.profile,
															securityDetails: {
																...this.state.profile?.securityDetails,
																familyMembers: {
																	...this.state.profile?.securityDetails?.familyMembers,
																	partner: {
																		...this.state.profile?.securityDetails?.familyMembers
																			?.partner,
																		address: {
																			...this.state.profile?.securityDetails?.familyMembers
																				?.partner?.address,
																			city: {
																				...this.state.profile?.securityDetails?.familyMembers
																				?.partner?.address?.city,
																				name: e
																			}
																		}
																	}
																}
															}
														}
													})
												}
												value={
													this.state.profile?.securityDetails?.familyMembers?.partner?.address?.city?.name
												}></Input>
										</div>
									</>
								)}
								<div className='fr-grid-row'>
									<div className='fr-col-12'>
										<Input
											title='Adresse'
											description='Numéro et rue'
											type={InputType.Text}
											value={this.state.profile?.securityDetails?.familyMembers?.partner?.address?.street}
											disabled={
												this.state.profile?.securityDetails?.familyMembers?.partner?.isUnknown ||
												(this.state.groupingIsValidate ?? false)
											}
											onChange={(e) => {
												this.setState({
													profile: {
														...this.state.profile,
														securityDetails: {
															...this.state.profile?.securityDetails,
															familyMembers: {
																...this.state.profile?.securityDetails?.familyMembers,
																partner: {
																	...this.state.profile?.securityDetails?.familyMembers
																		?.partner,
																	address: {
																		...this.state.profile?.securityDetails?.familyMembers
																			?.partner?.address,
																		street: e
																	}
																}
															}
														}
													}
												});
											}}></Input>
									</div>
								</div>
								<div className='fr-grid-row'>
									<div className='fr-col-12'>
										<Input
											title="Complément d'adresse"
											description='Bâtiment, immeuble, escalier...'
											type={InputType.Text}
											value={
												this.state.profile?.securityDetails?.familyMembers?.partner?.address
													?.additionalInformation
											}
											disabled={
												this.state.profile?.securityDetails?.familyMembers?.partner?.isUnknown ||
												(this.state.groupingIsValidate ?? false)
											}
											onChange={(e) => {
												this.setState({
													profile: {
														...this.state.profile,
														securityDetails: {
															...this.state.profile?.securityDetails,
															familyMembers: {
																...this.state.profile?.securityDetails?.familyMembers,
																partner: {
																	...this.state.profile?.securityDetails?.familyMembers
																		?.partner,
																	address: {
																		...this.state.profile?.securityDetails?.familyMembers
																			?.partner?.address,
																		additionalInformation: e
																	}
																}
															}
														}
													}
												});
											}}></Input>
									</div>
								</div>
								<div className='fr-grid-row'>
									<div className='fr-col-12'>
										<NationSelect
											label='Nationalité actuelle'
											value={
												this.state.profile?.securityDetails?.familyMembers?.partner?.nationalities
													?.currentNationalityId
											}
											type={ReferentialType.NATIONALITY}
											customOptionLabel={(o: any) => {
												return o.formattedNationality;
											}}
											disabled={
												this.state.profile?.securityDetails?.familyMembers?.partner?.isUnknown ||
												(this.state.groupingIsValidate ?? false)
											}
											onChange={(e) => {
												this.setState({
													profile: {
														...this.state.profile,
														securityDetails: {
															...this.state.profile?.securityDetails,
															familyMembers: {
																...this.state.profile?.securityDetails?.familyMembers,
																partner: {
																	...this.state.profile?.securityDetails?.familyMembers
																		?.partner,
																	nationalities: {
																		...this.state.profile?.securityDetails?.familyMembers
																			?.partner?.nationalities,
																		currentNationalityId: e?.id
																	}
																}
															}
														}
													}
												});
											}}
										/>
									</div>
								</div>
								<div className='fr-grid-row'>
									<div className='fr-col-12'>
										<NationSelect
											label='Nationalité de naissance'
											value={
												this.state.profile?.securityDetails?.familyMembers?.partner?.nationalities
													?.birthNationalityId
											}
											type={ReferentialType.NATIONALITY}
											customOptionLabel={(o: any) => {
												return o.formattedNationality;
											}}
											disabled={
												this.state.profile?.securityDetails?.familyMembers?.partner?.isUnknown ||
												(this.state.groupingIsValidate ?? false)
											}
											onChange={(e) => {
												this.setState({
													profile: {
														...this.state.profile,
														securityDetails: {
															...this.state.profile?.securityDetails,
															familyMembers: {
																...this.state.profile?.securityDetails?.familyMembers,
																partner: {
																	...this.state.profile?.securityDetails?.familyMembers
																		?.partner,
																	nationalities: {
																		...this.state.profile?.securityDetails?.familyMembers
																			?.partner?.nationalities,
																		birthNationalityId: e?.id
																	}
																}
															}
														}
													}
												});
											}}
										/>
									</div>
								</div>
								<div
									className='fr-grid-row'
									style={{ marginBottom: '2rem', flexDirection: 'column' }}>
									<div className='fr-col-12'>
										<NationSelect
											label='Autre nationalité'
											value={
												this.state.profile?.securityDetails?.familyMembers?.partner?.nationalities
													?.otherNationalityIdOne
											}
											type={ReferentialType.NATIONALITY}
											customOptionLabel={(o: any) => {
												return o.formattedNationality;
											}}
											disabled={
												this.state.profile?.securityDetails?.familyMembers?.partner?.isUnknown ||
												(!this.state.togglePartnerNationalityOne &&
													this.state.profile?.securityDetails?.familyMembers?.partner?.nationalities
														?.otherNationalityIdOne === 0) ||
												(this.state.groupingIsValidate ?? false)
											}
											onChange={(e) => {
												this.setState({
													profile: {
														...this.state.profile,
														securityDetails: {
															...this.state.profile?.securityDetails,
															familyMembers: {
																...this.state.profile?.securityDetails?.familyMembers,
																partner: {
																	...this.state.profile?.securityDetails?.familyMembers
																		?.partner,
																	nationalities: {
																		...this.state.profile?.securityDetails?.familyMembers
																			?.partner?.nationalities,
																		otherNationalityIdOne: e?.id
																	}
																}
															}
														}
													}
												});
											}}
										/>
									</div>
									{(this.state.togglePartnerNationalityTwo ||
										this.state.profile?.securityDetails?.familyMembers?.partner?.nationalities
											?.otherNationalityIdTwo !== 0) && (
										<NationSelect
											label='Autre nationalité'
											value={
												this.state.profile?.securityDetails?.familyMembers?.partner?.nationalities?.otherNationalityIdTwo?.toString() !==
												'0'
													? this.state.profile?.securityDetails?.familyMembers?.partner?.nationalities?.otherNationalityIdTwo?.toString()
													: ''
											}
											type={ReferentialType.NATIONALITY}
											customOptionLabel={(o: any) => {
												return o.formattedNationality;
											}}
											disabled={
												this.state.profile?.securityDetails?.familyMembers?.partner?.isUnknown ||
												(this.state.groupingIsValidate ?? false)
											}
											onChange={(e) => {
												this.setState({
													profile: {
														...this.state.profile,
														securityDetails: {
															...this.state.profile?.securityDetails,
															familyMembers: {
																...this.state.profile?.securityDetails?.familyMembers,
																partner: {
																	...this.state.profile?.securityDetails?.familyMembers
																		?.partner,
																	nationalities: {
																		...this.state.profile?.securityDetails?.familyMembers
																			?.partner?.nationalities,
																		otherNationalityIdTwo: e?.id
																	}
																}
															}
														}
													}
												});
											}}></NationSelect>
									)}
									<Button
										disabled={
											this.state.profile?.securityDetails?.familyMembers?.partner?.isUnknown ||
											(this.state.profile?.securityDetails?.familyMembers?.partner?.nationalities
												?.otherNationalityIdOne != undefined &&
												this.state.profile?.securityDetails?.familyMembers?.partner?.nationalities
													?.otherNationalityIdOne != 0 &&
												this.state.profile?.securityDetails?.familyMembers?.partner?.nationalities
													?.otherNationalityIdTwo != undefined &&
												this.state.profile?.securityDetails?.familyMembers?.partner?.nationalities
													?.otherNationalityIdTwo != 0) ||
											(this.state.togglePartnerNationalityOne && this.state.togglePartnerNationalityTwo) ||
											(this.state.groupingIsValidate ?? false)
										}
										size={ButtonSize.Small}
										text='Ajouter une nationalité'
										iconClassName='fr-icon-add-line'
										iconState={ButtonIconState.Left}
										type={ButtonType.Tertiary}
										onClick={() => {
											this.setState({
												togglePartnerNationalityTwo: this.state.togglePartnerNationalityOne,
												togglePartnerNationalityOne: true
											});
										}}></Button>
								</div>
								<div className='fr-grid-row'>
									<div className='fr-col-12'>
										<Input
											title="Année d'acquisition de la nationalité française"
											type={InputType.Text}
											value={
												this.state.profile?.securityDetails?.familyMembers?.partner?.nationalities?.obtainingFrenchNationalityYear?.toString() !==
												'0'
													? this.state.profile?.securityDetails?.familyMembers?.partner?.nationalities?.obtainingFrenchNationalityYear?.toString()
													: ''
											}
											disabled={
												this.state.profile?.securityDetails?.familyMembers?.partner?.isUnknown ||
												(this.state.groupingIsValidate ?? false)
											}
											onChange={(e) => {
												this.setState({
													profile: {
														...this.state.profile,
														securityDetails: {
															...this.state.profile?.securityDetails,
															familyMembers: {
																...this.state.profile?.securityDetails?.familyMembers,
																partner: {
																	...this.state.profile?.securityDetails?.familyMembers
																		?.partner,
																	nationalities: {
																		...this.state.profile?.securityDetails?.familyMembers
																			?.partner?.nationalities,
																		obtainingFrenchNationalityYear: +e
																	}
																}
															}
														}
													}
												});
											}}></Input>
									</div>
								</div>
								<div className='fr-grid-row'>
									<div className='fr-col-12'>
										<Input
											title="Année d'arrivée en France"
											type={InputType.Text}
											value={
												this.state.profile?.securityDetails?.familyMembers?.partner?.nationalities?.arrivalInFrance?.toString() !==
												'0'
													? this.state.profile?.securityDetails?.familyMembers?.partner?.nationalities?.arrivalInFrance?.toString()
													: ''
											}
											disabled={
												this.state.profile?.securityDetails?.familyMembers?.partner?.isUnknown ||
												(this.state.groupingIsValidate ?? false)
											}
											onChange={(e) => {
												this.setState({
													profile: {
														...this.state.profile,
														securityDetails: {
															...this.state.profile?.securityDetails,
															familyMembers: {
																...this.state.profile?.securityDetails?.familyMembers,
																partner: {
																	...this.state.profile?.securityDetails?.familyMembers
																		?.partner,
																	nationalities: {
																		...this.state.profile?.securityDetails?.familyMembers
																			?.partner?.nationalities,
																		arrivalInFrance: +e
																	}
																}
															}
														}
													}
												});
											}}></Input>
									</div>
								</div>
								<div className='fr-grid-row'>
									<div className='fr-col-12'>
										<Input
											title='Date de décès'
											type={InputType.Date}
											value={DateService.Convert(
												this.state.profile?.securityDetails?.familyMembers?.partner?.dateOfDeath || ''
											)}
											disabled={
												this.state.profile?.securityDetails?.familyMembers?.partner?.isUnknown ||
												(this.state.groupingIsValidate ?? false)
											}
											onChange={(e) => {
												this.setState({
													profile: {
														...this.state.profile,
														securityDetails: {
															...this.state.profile?.securityDetails,
															familyMembers: {
																...this.state.profile?.securityDetails?.familyMembers,
																partner: {
																	...this.state.profile?.securityDetails?.familyMembers
																		?.partner,
																	dateOfDeath: DateService.Convert(e)
																}
															}
														}
													}
												});
											}}></Input>
									</div>
								</div>
								<div className='fr-grid-row'>
									<div className='fr-col-12'>
										<Input
											title='Profession'
											type={InputType.Text}
											value={this.state.profile?.securityDetails?.familyMembers?.partner?.jobTitle}
											disabled={
												this.state.profile?.securityDetails?.familyMembers?.partner?.isUnknown ||
												(this.state.groupingIsValidate ?? false)
											}
											onChange={(e) => {
												this.setState({
													profile: {
														...this.state.profile,
														securityDetails: {
															...this.state.profile?.securityDetails,
															familyMembers: {
																...this.state.profile?.securityDetails?.familyMembers,
																partner: {
																	...this.state.profile?.securityDetails?.familyMembers?.partner,
																	jobTitle: e
																}
															}
														}
													}
												});
											}}></Input>
									</div>
								</div>
								<MobileView>
									{!this.state.groupingIsValidate && (
										<div className='fr-grid-row fr-grid-row--center'>
											<div className='fr-col-12'>
												<Button
													className='mobile-button'
													disabled={false}
													onClick={() => {
														this.updateProfile(() => {
															this.displaySuccess();
														});
													}}
													text='Enregistrer mes informations'
													type={ButtonType.Primary}
													iconClassName=''
													size={ButtonSize.Medium}
													modalId=''></Button>
											</div>
										</div>
									)}
								</MobileView>
								<BrowserView>
									{!this.state.groupingIsValidate && (
										<div className='fr-grid-row fr-grid-row--right'>
											<div className='fr-col-12 fr-align-right'>
												<Button
													disabled={false}
													onClick={() => {
														this.updateProfile(() => this.displaySuccess());
													}}
													text='Enregistrer mes informations'
													type={ButtonType.Primary}
													iconClassName=''
													size={ButtonSize.Medium}
													modalId=''></Button>
											</div>
										</div>
									)}
								</BrowserView>
							</TabItem>
						</Tabs>
					</div>
				</div>
			</Container>
		);
	}
}
export default RenseignementsFamille;
