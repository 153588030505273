import React from 'react';
import QueryString from 'qs';
import JobService from '../../services/JobService';

class Postuler extends React.Component<{ location: Location }> {
	constructor(props: any) {
		super(props);
		const parsedQueryString = QueryString.parse(this.props.location.search, {
			ignoreQueryPrefix: true
		});

		/* jobTitle and jobCategoryId requis pour postuler */
		if (
			parsedQueryString.army_id &&
			parsedQueryString.origin_id &&
			parsedQueryString.job_title &&
			parsedQueryString.job_category_id
		) {
			localStorage.setItem('armyId', parsedQueryString.army_id.toString());
			localStorage.setItem('originId', parsedQueryString.origin_id.toString());
			localStorage.setItem('jobTitle', parsedQueryString.job_title.toString());
			localStorage.setItem('jobCategoryId', parsedQueryString.job_category_id.toString());
			if (parsedQueryString.max_age) {
				localStorage.setItem('maxAge', parsedQueryString.max_age.toString());
			}

			if (localStorage.getItem('auth')) {
				this.AddJobOfInterest(
					parsedQueryString.job_category_id.toString(),
					parsedQueryString.job_title.toString(),
					parsedQueryString.max_age != undefined ? parsedQueryString.max_age.toString() : ''
				);
			} else {
				var login =
					'/login' +
					'?army_id=' +
					parsedQueryString.army_id.toString() +
					'&origin_id=' +
					parsedQueryString.origin_id.toString() +
					'&job_title=' +
					parsedQueryString.job_title.toString() +
					'&job_category_id=' +
					parsedQueryString.job_category_id.toString() +
					(parsedQueryString.max_age ? '&max_age=' + parsedQueryString.max_age.toString() : '');

				setTimeout(() => {
					window.location.href = login;
				}, 3000); /* wait 3 seconds before redirect */
			}
		} else {
			localStorage.removeItem('armyId');
			localStorage.removeItem('originId');
			localStorage.removeItem('jobTitle');
			localStorage.removeItem('jobCategoryId');
			localStorage.removeItem('maxAge');

			setTimeout(() => {
				window.location.href = '/login?bad';
			}, 3000); /* wait 3 seconds before redirect */
		}
	}

	async AddJobOfInterest(jobCategoryId: string, jobTitle: string, maxAge: string) {
		await JobService.addJobOfInterest(jobCategoryId, jobTitle, maxAge).then((result) => {
			setTimeout(() => {
				window.location.href = '/postes-souhaites';
			}, 3000); /* wait 3 seconds before redirect */
			if (result.status == 204) {
				localStorage.removeItem('maxAge');
			}
		});
	}

	render() {
		return (
			<div className='fr-container'>
				<div
					className='fr-follow'
					style={{ marginTop: '50px' }}>
					<div className='fr-grid-row'>
						<div
							className='fr-col-12'
							style={{ textAlign: 'center' }}>
							<div
								className='fr-follow__social'
								style={{ marginBottom: '20px' }}>
								<div className='loader'></div>
							</div>
						</div>
					</div>
					<div className='fr-grid-row'>
						<div
							className='fr-col-12'
							style={{ textAlign: 'center' }}>
							<div
								className='fr-follow__social'
								style={{ flexDirection: 'column', marginBottom: '20px' }}>
								<h2 className='fr-h5'>
									Veuillez patienter, vous allez être redirigé vers votre espace candidat.
								</h2>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default Postuler;
