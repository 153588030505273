import http, { baseURL } from '../../http-common';
import { NephosESLScopPoolCity } from '../../sparta.api';

class AppointmentAvailabilityPublicService {
	private routeUrl = '/api/public/appointmentavailabilities/';

	async search(model: GetAppointmentsAvailabilitiesFilter) {
		const getResp = await http.post<{ data: AppointmentAvailabilitiesDisplayModel[] }>(
			baseURL + this.routeUrl + 'search',
			model
		);
		return (await getResp).data;
	}

	async check(model: GetAppointmentAvailabilityFilter) {
		console.log('check start...');
		const getResp = await http.post<{ data: boolean; errors: NephosError[] }>(baseURL + this.routeUrl + 'check', model, {
			validateStatus: function (status) {
				return true;
			}
		});

		switch (getResp.status) {
			case 200:
				return (await getResp).data;
			case 400:
				return (await getResp).data;
			default:
				return (await getResp).data; // TODO for other errors.
		}
	}
}

export interface NephosError {
	code: string;
	message: string;
}

export interface GetAppointmentAvailabilityFilter {
	TenantID: number;
	AppointmentId: number;
	ApplicantEmail: string;
}

export interface GetAppointmentsAvailabilitiesFilter {
	TenantID: number;
	CirfaID: number;
	ConfigurationID?: number;
	AppointmentType?: AppointmentType;
	Modality?: AppointmentModalityType;
	PageNumber: number;
	PageSize: number;
}
export interface AppointmentAvailabilitiesDisplayModel {
	id: string;
	cirfaName: string;
	appointmentType: AppointmentType;
	appointmentLabel: string;
	description: string;
	street: string;
	city: NephosESLScopPoolCity;
	cityId: number;
	phoneNumber: string;
	appointmentModalityType: AppointmentModalityType;
	appointmentAvailabilityTimeSlots: AppointmentAvailabilityTimeSlot[];
	durationInMinutes: string;
	configurationId: number;
	hasMore: boolean;
}

export interface AppointmentAvailabilityTimeSlot {
	date: Date;
	startTime?: any;
	endTime?: any;
	durationInMinutes: number;
	appointmentId: number;
	appointmentConfigurationId: number;
}

export enum AppointmentType {
	Unknown = 0,
	Individual = 1,
	Collective = 2,
	Permanence = 3,
	All = 123
}

export enum AppointmentModalityType {
	Unknown = 0,
	Physical = 1,
	Phone = 2,
	Visio = 3
}

export enum AppointmentCategory {
	Unknown = 0,
	Information = 1,
	FollowUp = 2,
	Unavailability = 3
}

export default new AppointmentAvailabilityPublicService();
